import React, {useEffect, useState} from 'react';
import {publicRoutes} from '../context/AppRoutes'
import {Routes, Route, useLocation} from "react-router-dom";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PortfolioPg from "./pg_Portfolio";

const AppRouter = () => { console.log('AppRouter')
    const location = useLocation()

    // footer animation, when we change page
    useEffect(() => {
        document.querySelector('.content .footer').style.opacity = '0'
        setTimeout(function () {
            document.querySelector('.content .footer').style.opacity = '1'
        }, 3500)
    }, [location.pathname])

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} timeout={500} classNames="pg" >
                <Routes location={location}>
                    {publicRoutes.map((route) => (
                        <Route path={route.path} key={route.id}>
                            <Route
                                path={":lang"}
                                element={route.component}
                                exact={route.exact}
                            />
                            <Route
                                path={""}
                                element={route.component}
                                exact={route.exact}
                            />
                        </Route>
                    ))}
                        <Route
                            path={'/case'}
                            element={<PortfolioPg/>}
                            exact={true}
                        />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default AppRouter