import React, {useEffect, useRef, useState} from 'react'
import './styles/main.css'
import {BrowserRouter} from "react-router-dom"
import {AuthContext} from './context/Context.js'
import AppRouter from "./pages/AppRouter"
import MainMenu from "./components/MainMenu"
import TopMenu from "./components/TopMenu"
import Footer from "./components/Footer";
import Popups from "./components/Popups";
import {globPrice_Exchange} from "./context/GlobPrices";
import axios from "axios";

const App = () => { console.log('App')
    // use URL language -for start
    const urlQuery = new URL(window.location)
    let urlLang = urlQuery.pathname.split('/')
    let urlLoc = urlLang[1]
    urlLang = urlLang[urlLang.length - 1]
    if (urlLang !== 'ru' && urlLang !== 'en'){
        if(localStorage.getItem("lang")){
            urlLang = localStorage.getItem("lang")
        }else{
            urlLang = 'en'
        }
    }

    // use URL location for main menu - for start
    if(urlLoc.length < 3){urlLoc = 'main'}

    // states
    const [appFirstLoad, setAppFirstLoad] = useState(true)
    const [lang, setLang] = useState(urlLang)
    const [menuPage, setMenuPage] = useState(urlLoc)
    const [isLandscape, setIsLandscape] = useState(window.matchMedia('(min-width: 25cm)').matches)
    const [isLaptop, setIsLaptop] = useState(window.matchMedia('(min-width: 25cm) and (max-width: 40cm)').matches)
    const [isPad, setIsPad] = useState(window.matchMedia('(max-width: 25cm) and (min-width: 15cm)').matches)
    const [pops, setPops] = useState({
        p0: false, // popup fon
        p1: false, // popup - free estimate
        p2: false, // popup - make money with us
        p3: false, // popup - discount
        p4: false, // popup - payment
        p5: false, // popup - message
        p6: false, // free variable, you can use it for new popup
        p7: false, // free variable, you can use it for new popup
        p8: false, // free variable, you can use it for new popup
        p9: false, // free variable, you can use it for new popup
    })
    const [exchange, setExchange] = useState(globPrice_Exchange)
    // for listeners
    const [pgTeamFirstLoad, setPgTeamFirstLoad] = useState(false)
    const [toTopListener, setToTopListener] = useState(false)
    const [resizeListener, setResizeListener] = useState(false)
    const [exchRequest, setExchRequest] = useState(false)

    // refs
    const bgPoints = useRef()

    // loading
    useEffect(() => {
        setTimeout(function (){
            bgPoints.current.style.opacity = '1'
        }, 1500) // появляются точки на фоне
    }, [])
    // change lang attr for HTML tag
    useEffect(() => {
        document.querySelector('html').setAttribute('lang', lang)
    }, [lang])
    // on resize - do reload
    if(!resizeListener){
        setResizeListener(true)
        window.addEventListener("resize", () => {
            if(window.matchMedia('(min-width: 25cm)').matches){
                if(!isLandscape){
                    let reload = window.location.href
                    window.location.href = reload
                    setIsLandscape(true)
                }else{
                    if(window.matchMedia('(max-width: 40cm)').matches){
                        setIsLaptop(true)
                    }else{
                        setIsLaptop(false)
                    }
                }
            }else{
                if(isLandscape){
                    let reload = window.location.href
                    window.location.href = reload
                    setIsLandscape(false)
                }else{
                    if(window.matchMedia('(min-width: 15cm)').matches){
                        setIsPad(true)
                    }else{
                        setIsPad(false)
                    }
                }
            }
        })
    }
    // load dollar exchange
    if(!exchRequest){
        setExchRequest(true)
        axios.get(`/toexchange.php`).then((res) => {
            if(res.data && res.data.length < 10){
                setExchange(res.data)
                console.log("ex: " + exchange)
            }else{
                setExchange(globPrice_Exchange)
                console.log("ex: " + exchange)
            }
        }).catch(() => {
            setExchange(globPrice_Exchange)
            console.log("ex: " + exchange)
        })
    }

    return(
        <AuthContext.Provider value={{
            appFirstLoad: appFirstLoad,
            setAppFirstLoad: setAppFirstLoad,
            pgTeamFirstLoad: pgTeamFirstLoad,
            setPgTeamFirstLoad: setPgTeamFirstLoad,
            lang: lang,
            setLang: setLang,
            exchange: exchange,
            isLandscape: isLandscape,
            isLaptop: isLaptop,
            isPad: isPad,
            pops: pops,
            setPops: setPops,
            menuPage: menuPage,
            setMenuPage: setMenuPage,
            toTopListener: toTopListener,
            setToTopListener: setToTopListener,
        }}>
            <BrowserRouter>
                <Popups/>
                <div className="body-wrp">
                    <div className="body-wrp-before" ref={bgPoints}>{}</div>
                    <MainMenu/>
                    {isLandscape ? <div className="navmenu-below">{}</div> : ''}
                    <div className="content">
                        <TopMenu/>
                        <div className="content-body">
                            <AppRouter/>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </BrowserRouter>
        </AuthContext.Provider>
    )
}

export default App