import React from "react";
import TeamPg from "../pages/pg_Team";
import CalculatorPg from "../pages/pg_Calculator";
import PortfolioPg from "../pages/pg_Portfolio";
import ReviewsPg from "../pages/pg_Reviews";
import StepsPg from "../pages/pg_Steps";
import FilesPg from "../pages/pg_Files";
import MainPg from "../pages/pg_Main";
import AnswersPg from "../pages/pg_Answers";
import ServicesPg from "../pages/pg_Services";
import PaymentGood from "../pages/pg_PaymentGood";
import PgWork from "../pages/pg_Work";

console.log('AppRoutes')

export const publicRoutes = [
    {name_en: 'Main', name_ru: 'Главная', id: 'main', path: '/', component: <MainPg/>, exact: true},
    {name_en: 'Services Range', name_ru: 'Спектр услуг', id: 'services', path: '/services', component: <ServicesPg/>, exact: true},
    {name_en: 'Price Calculator', name_ru: 'Калькулятор цен', id: 'calculator', path: '/calculator', component: <CalculatorPg/>, exact: true},
    {name_en: 'Portfolio', name_ru: 'Портфолио', id: 'portfolio', path: '/portfolio', component: <PortfolioPg/>, exact: true},
    {name_en: 'Customer Reviews', name_ru: 'Отзывы клиентов', id: 'reviews', path: '/reviews', component: <ReviewsPg/>, exact: true},
    {name_en: 'Work Scheme', name_ru: 'Схема работы', id: 'steps', path: '/steps', component: <StepsPg/>, exact: true},
    {name_en: 'Docs & Files', name_ru: 'Документы', id: 'files', path: '/files', component: <FilesPg/>, exact: true},
    {name_en: 'Team', name_ru: 'Команда', id: 'team', path: '/team', component: <TeamPg/>, exact: true},
    {name_en: 'Answers', name_ru: 'Ответы', id: 'answers', path: '/answers', component: <AnswersPg/>, exact: true},

    {name_en: 'One work', name_ru: 'Одна работа', id: 'work', path: '/case/:id', component: <PgWork/>, exact: true},
    {name_en: 'Successful payment', name_ru: 'Успешная оплата', id: 'payment-good', path: '/payment-good', component: <PaymentGood/>, exact: true},
    {name_en: 'Error 404', name_ru: 'Ошибка 404', id: '404', path: '*', component: <MainPg/>, exact: true},
]
