console.log('text_pg_AnswersText')

export const pg_AnswersText0 = [
    {   id: 0,
        en: "Frequently asked questions",
        ru: "Часто задаваемые вопросы",
    },
    {   id: 1,
        en: "This section contains answers to popular questions that clients are asking. If the answer to your question is not fully disclosed or is completely absent from this list, write it by clicking on the button «I want to ask about something else»",
        ru: "В данном разделе собраны ответы на популярные вопросы не дающие покоя клиентам. Если ответ на ваш вопрос не раскрыт полностью или вовсе отсутствует в этом списке - напишите его, нажав на кнопку «Хочу спросить о другом»",
    },
    {   id: 2,
        en: "I want to ask about something else",
        ru: "Хочу спросить о чём-то другом",
    },
]

export const pg_AnswersText1 = [
    {   id: 0,
        en: "How much does it cost to create a website / app?",
        ru: "Сколько стоит создание сайта / приложения?",
    },
    {   id: 1,
        en: "How do we get payment for ready work?",
        ru: "Как проходит оплата проделанной работы?",
    },
    {   id: 2,
        en: "Who will fill, promote and manage a project?",
        ru: "Кто будет наполнять, продвигать и управлять проектом?",
    },
    {   id: 3,
        en: "Can we expand project functionality in the future?",
        ru: "Можно ли позднее расширить функционал?",
    },
    {   id: 4,
        en: "Will it be a unique design or design template?",
        ru: "Будет уникальный дизайн или шаблон?",
    },
    {   id: 5,
        en: "How long is a website / app development?",
        ru: "Сколько времени создается проект?",
    },
    {   id: 6,
        en: "Which instruments will be a project created?",
        ru: "Какими инструментами будет создан проект?",
    },
    {   id: 7,
        en: "What will happen if we don't like the result?",
        ru: "Что если нам не понравится результат?",
    },
    {   id: 8,
        en: "Where will the created project be located?",
        ru: "Где будет находиться созданный проект?",
    },
    {   id: 9,
        en: "Had you found a lower price at another studio?",
        ru: "Вы нашли цену ниже в другой студии?",
    },
    {   id: 10,
        en: "Can we work with / without a contract?",
        ru: "Можем ли работать по / без договора?",
    },
]

export const pg_AnswersText2 = [
    {   id: 0,
        en: "The cost of the project depends on several factors. First of all, it depends on the quantity and complexity of the functionality you want to receive. Secondly, it depends on the level of specialists you choose to work with. The price least of all depends on what tools will be used to implement the plan. You can choose all this at the assessment stage. If you want, we will select for you the optimal solution to your problem, in our opinion. If the price seems high to you, then you can always come up with something to simplify, and what to postpone altogether to the next stage of development.",
        ru: "Стоимость проекта зависит от нескольких факторов. Прежде всего от количества и сложности функционала который вы хотите получить. Во вторую очередь от уровня специалистов которых вы выберите для работы. Менее всего цена зависит от того какими инструментами будет реализовано задуманное. Всё это вы сможете выбрать на этапе оценки. Если же вы захотите, то мы подберем вам оптимальный на наш взгляд вариант решения вашей задачи. Если же цена покажется Вам высокой, то всегда можно придумать что упростить, а что и вовсе отложить на следующий этап разработки.",
    },
    {   id: 1,
        en: "Project creation is most often divided into the following stages: Design, Frontend, Backend, API`s and Synchronization`s. After the assessment, the cost of each stage will be known. Then everything is simple: If you have already worked with us, then you pay upon completion of work stages. If you are contacting us for the first time, you will need to make a 50% prepayment for every stage of work as we begin them. We need this behavior with new clients to make sure that your intentions are serious.",
        ru: "Создание проекта чаще всего делится на следующие этапы: Проектирование, Дизайн, Фронтенд, Бэкенд, API и Синхронизации. После оценки у каждого этапа будет известна стоимость. Далее всё просто: Если вы уже работали с нами, то вы платите по факту выполнения / приемки этапов работы. Если же вы обращаетесь к нам впервые, то необходимо будет делать 50% предоплату этапов работы - по мере того как мы к ним приступаем. Такое поведение с новыми клиентами необходимо нам для того чтобы убедится в серьезности ваших намерений.",
    },
    {   id: 2,
        en: "Once ready, you will have full access to the file system and the admin panel, from where you can fully manage the project. Sometimes a client asks not only for creation, but also for project management services. This often happens due to lack of time or due to not knowing how to do it better and more efficiently. In this case, we can take on part or all of the management work ourselves. Also, if our management services seem expensive, but you have no experience in selecting IT specialists, then we can help you with the selection and interview of specialists for the positions you need.",
        ru: "По готовности у вас будет все доступы к файловой системе и админ-панели, откуда вы сможете полностью управлять проектом. Иногда клиент обращается не только за созданием, но и за услугами по ведению проекта. Такое часто бывает из за нехватки времени или из за незнания как лучше и эффективнее это делать. В таком случае мы можем взять часть или все работы по ведению на себя. Также если наши услуги по ведению покажутся дорогими, а опыта по подбору it-специалистов нет, то мы сможем помочь вам с подбором и собеседованием специалистов на нужные вам должности.",
    },
    {   id: 3,
        en: "Of course! If we initially created the project for you, then there are no restrictions on expansion and growth. Often the client wants everything at once - this is not always good and we try to dissuade from excesses at the start. So, by creating only the key functionality at the start, you save money and time without burning out in expectations. Also you will evaluate what is more important to improve and where to spend on money.",
        ru: "Конечно можно! Если проект вам изначально создавали мы, то ограничений по расширению и росту нет. Часто клиент хочет всё и сразу - это не всегда хорошо и мы стараемся отговорить от очевидных излишек на старте. Так создав на старте только ключевой функционал - вы экономите деньги и время не перегорев в ожиданиях, а также на деле оцените что важнее улучшить и на что потратить деньги.",
    },
    {   id: 4,
        en: "We do not work with templates, even upon request. Be it Design, Frontend or the entire Website. There are many reasons for this. If you like some template and you want the same solution - no problem, we will create an individual solution based on the template. The result will be guaranteed better, and in the long run it will probably also be cheaper.",
        ru: "Мы не работаем с шаблонами, даже по просьбе. Будь то дизайн, фронтенд или сайт целиком. Причин для этого очень много. Если же вам нравится какой то шаблон и вы хотите такое же решение - без проблем, мы создадим вам индивидуальное решение опираясь на шаблон. Полученный результат будет гарантированно лучше, а в долгосрочной перспективе наверняка ещё и дешевле.",
    },
    {   id: 5,
        en: "As with the cost - with timing everything depends on the quantity and complexity of the functionality. Also what level of specialists who are working on the project. And of course, it depends on what tools are used. You will learn the exact deadlines at the assessment stage of your project. But unlike the price, we can reduce the timeframe by adding additional specialist to the project. So, for example, by involving another designer, we will speed up the design stage of the project by 2 times. But it is important to understand that the cost of this stage will increase by about 20%.",
        ru: "Как и со стоимостью у сроков всё зависит от количества и сложности функционала. Также от уровня специалистов что работают над проектом. Ну и конечно от того какие инструменты используются. Точные сроки вы узнаете на этапе оценки вашего проекта. Но в отличии от цены, сроки мы можем сократить благодаря подключению к проекту дополнительных мастеров. Так например подключив ещё одного дизайнера - ускорим этап дизайна проекта в 2 раза. Но важно понимать, что и стоимость этого этапа возрастет примерно на 20%.",
    },
    {   id: 6,
        en: "If we are talking about websites, we create technically complex projects mainly in React + Keystone (NodeJs). Simple projects are created mainly in JQ + Wordpress (PHP). At a design stage, work is most often carried out in Figma. Tools such as Python and Objective C are practically not used. If we are talking about a mobile NOT web application, then we use Kotlin or Objective C.",
        ru: "Если мы говорим о сайтах, то технически сложные проекты создаем преимущественно на React + Keystone (NodeJs). Простые проекты создаются в основном на JQ + Wordpress (PHP). На этапе проектирования и дизайна работы ведуться чаще всего в Figma. Такие инструменты как Python, Objective C - используются гораздо реже. Если же речь идёт о мобильном НЕ веб приложении, то мы используем Kotlin или Objective C.",
    },
    {   id: 7,
        en: "Now I will explain why this is impossible. We will agree with you on the functional list and block diagram of the future site. Next, we create design layouts based on the references approved by you, and again agree with you. After receiving your approval, we will implement the client part of the site strictly in accordance with the design layouts you agreed upon. And so on with each development step... With this approach, it is simply impossible to remain dissatisfied - because each stage undergoes careful control on your part before it is completed. But if something is not implemented as we agreed, then of course we will complete / redo everything FREE OF CHARGE to the agreed state.",
        ru: "Сейчас объясню почему это невозможно. Мы согласуем с вами функциональный список и блок-схему будущего сайта. Далее создаём дизайн-макеты по одобренным вами референсам, и снова согласуем с вами. После получения вашего одобрения - мы реализуем клиентскую часть сайта строго в соответствии с согласованными вами дизайн-макетами. И так будет с каждым шагом разработки. При таком подходе просто невозможно остаться не довольным, т.к. каждый этап прежде чем завершится проходит тщательный контроль с вашей стороны. Но если что то будет реализовано не так как мы договаривались, то мы конечно же всё доделаем / переделаем БЕСПЛАТНО до оговоренного состояния.",
    },
    {   id: 8,
        en: "At the creation stage, the project will be located on our servers. When ready, everything will be transferred to the server / hosting registered to you. Only those to whom you give logins and passwords will have access. Even we cannot change anything without your permission. Domain names will also be registered in your name. Also, for particularly sophisticated clients, we can immediately set up GIT for further work with the project.",
        ru: "На этапе создания проект будет находится на наших серверах. По готовности всё будет перенесено на сервер / хостинг оформленный на вас. Доступы будут иметь только те, кому вы дадите логины и пароли. Даже мы не сможем ничего поменять без вашего разрешения. Доменные имена будут оформлены также на вас. Также, особо искушенным клиентам мы можем сразу настроить GIT для дальнейшей работы с проектом.",
    },
    {   id: 9,
        en: "Be sure to compare the deadlines, portfolio, tools and level of specialists who will create your project. If a third-party studio has all these points better than ours, then of course it’s better to go to them. If some of the points are controversial and you doubt who to choose, then we recommend you contact us and tell about your pain - we will definitely find a solution on where you can save.",
        ru: "Обязательно сравните сроки, портфолио, инструменты и уровень специалистов которые будут создавать ваш проект. Если все эти пункты у сторонней студии лучше, чем у нас, то конечно же лучше идти к ним. Если же какие то из пунктов спорные и вы  сомневаетесь кого выбрать, то рекомендуем вам связаться с нами и рассказать о своих болях - мы обязательно найдем решение на чём можно сэкономить.",
    },
    {   id: 10,
        en: "If we are sponsors or partners of your project, then an contract is required. Otherwise, signing the contract is not necessary for us. This happens because we cannot force you to pay, and we risk only the last stage of development. To work, we only need an agreed functional list and a flowchart. But of course, we can always conclude a legal contract for you, which will describe the important requirements for our work. To draw up a contract, we have various templates and details from different countries.",
        ru: "Если мы являемся спонсорами или партнёрами вашего проекта, то договор обязателен. В противном случае подписание договора для нас не обязательно. Так происходит потому что мы рискуем только временем, а заставить платить вас мы всё равно не можем. Для работы же нам достаточно согласованного функционального списка и блок-схемы. Но для Вас мы конечно же всегда можем заключить юридический договор, где будут описаны важные требования к нашей работе. Для составления договора у нас есть различные шаблоны и реквизиты из разных стран.",
    },
]


































