console.log('text_pg_Services')

// повторяющаяся ДОРАБОТКА
const pgSrv_improvement = {
    cat_en: "Improvement of current",
    cat_ru: "Доработка текущего",
    works: [
        {
            work_en: "Add or Change functionality",
            work_ru: "Добавить / Изменить функционал",
            txt_en: "We can easily add new functionality or redo the work of some element. To make an accurate estimate, we need to take a look at your project's code (we'll need permissions). You can make an approximate estimate yourself using the calculator.",
            txt_ru: "Легко добавим новый функционал или переделаем работу какого-то элемента. Для точной оценки нам нужно взглянуть на код вашего проекта (понадобятся права доступа). Примерную оценку вы можете сделать самостоятельно в калькуляторе.",
            calc: true,
        },
        {
            work_en: "Code Refactoring",
            work_ru: "Рефакторинг кода",
            txt_en: "There is no limit to perfection! We'll find it ourselves or you can point to the code you want to improve. Correctly written code affects the speed of work, the number of errors, versatility of use on different devices, the ability to expand functionality, and much more.",
            txt_ru: "Нет предела совершенству! Найдём сами или вы укажите на код который вы хотите улучшить. Правильно написанный код влияет на скорость работы, количество ошибок, универсальность использования на разных устройствах, возможность расширения функционала и многое другое.",
            calc: true,
        },
        {
            work_en: "Repair of breakdowns",
            work_ru: "Ремонт поломок",
            txt_en: "Has your project partially or completely stopped working? Don't panic! We will immediately take your task to work. We will need access rights from your hosting (server) and admin panel(s). We’ll also tell you why this happened and how to avoid it in the future.",
            txt_ru: "Ваш проект частично или полностью перестал работать? Без паники! Мы сразу же возьмём вашу задачу в работу. Нам понадобятся права доступа от вашего хостинга (сервера) и админпанели(ей). Также расскажем почему так получилось и как этого избежать в будущем.",
            calc: true,
        },
        {
            work_en: "Redesign",
            work_ru: "Редизайн",
            txt_en: "Is your project working well, but you don't like the way it looks? Maybe it is displayed with errors? Or maybe you want to add interactivity to your design? We will develop layouts, coordinate with you and redesign the project.",
            txt_ru: "Ваш проект хорошо работает, но вам не нравится как он выглядит? Может быть он отображаться с ошибками? А может вы хотите добавить интерактива в дизайн? Мы разработаем макеты, согласуем с вами и переделаем дизайн проекта.",
            calc: true,
        },
    ],
}
const pgSrv_improvementAdd_1 = {
    work_en: "Unban in online stores",
    work_ru: "Снятие бана в маркетплейсе",
    txt_en: "If your application is banned from an online store, we will help you understand what happened and bring the application into compliance with new requirements.",
    txt_ru: "Если ваше приложение забанили в маркетплейсе - мы поможем разобраться в произошедшем и привести приложение в соответствие новым требованиям.",
    calc: true,
}
const pgSrv_improvementAdd_2 = {
    work_en: "Increasing site loading speed",
    work_ru: "Увеличение скорости загрузки сайта",
    txt_en: "We will identify and correct problems in the speed of the site. If necessary, we will modify the code for mass optimization of media content. For an accurate assessment, we will need access rights. If you have diagnosed problem areas, you can make a rough estimate using the calculator.",
    txt_ru: "Выявим и исправим проблемы в скорости работы сайта. При необходимости доработаем код для массовой оптимизации медиа контента. Для точной оценки нам потребуются права доступа. Если вы диагностировали проблемные места, то можете провести приблизительную оценку в калькуляторе.",
    calc: true,
}
const pgSrv_improvementAdd_3 = {
    work_en: "SEO work",
    work_ru: "SEO работы",
    txt_en: "Search engines rank sites that meet a number of requirements to the top. These requirements relate to code, content, additionally installed services, and even server settings. We will bring your site into compliance with these requirements.",
    txt_ru: "Поисковые системы поднимают в топ сайты соответствующие ряду требований. Эти требования касаются кода, контента, дополнительно установленных сервисов и даже настроек сервера. Мы приведём ваш сайт в соответствие этим требованиям.",
    calc: true,
}
const pgSrv_improvementAdd_4 = {
    work_en: "ASO work",
    work_ru: "ASO работы",
    txt_en: "Apple и Google stores rank apps that meet a number of requirements to the top. These requirements relate to code, content, additionally installed services, and even server settings. We will bring your app into compliance with these requirements.",
    txt_ru: "Магазины Apple и Google поднимают в топ приложения соответствующие ряду требований. Эти требования касаются кода, контента, дополнительно установленных сервисов и даже настроек сервера. Мы приведём ваше приложение в соответствие этим требованиям.",
    calc: true,
}
// сборка
let pgSrv_improvementBig_1 = structuredClone(pgSrv_improvement)
pgSrv_improvementBig_1.works.push(pgSrv_improvementAdd_4)
pgSrv_improvementBig_1.works.push(pgSrv_improvementAdd_1)
let pgSrv_improvementBig_2 = structuredClone(pgSrv_improvement)
pgSrv_improvementBig_2.works.push(pgSrv_improvementAdd_3)
pgSrv_improvementBig_2.works.push(pgSrv_improvementAdd_2)

// повторяющаяся ДИАГНОСТИКА
const pgSrv_diagnostic = {
    cat_en: "Diagnostics of current",
    cat_ru: "Диагностика текущего",
    works: [
        {
            work_en: "Usability, Design & Content analytics",
            work_ru: "Аналитика юзабилити, дизайна и контента",
            txt_en: "Do you have doubts that your project looks decent, and that it is convenient and pleasant for clients to use it? We will conduct an analysis of shortcomings and weaknesses are found. After that we will present you with an analysis of your project and options for improving it.",
            txt_ru: "У вас есть сомнения в том что ваш проект выглядит достойно, а клиентам удобно и приятно им пользоваться? Проведём аналитику, а по обнаруженным недочетам и слабым местам - презентуем вам разбор вашего проекта и вариантов по его улучшению.",
            calc: true,
        },
        {
            work_en: "Analytics of technical implementation",
            work_ru: "Аналитика технической реализации",
            txt_en: "The project runs slowly, freezes, and the admin panel is a mess that is impossible to sort out without a programmer? Let's get under the hood of your project and explain to you in detail how things are going there! We will also offer you options for improving the identified weaknesses.",
            txt_ru: "Проект работает медленно, зависает, а в админ-панели бардак в котором без программиста невозможно разобраться? Залезем под капот вашему проекту и подробно объясним вам как там обстоят дела! Также предложим вам варианты по улучшению обнаруженных, слабых мест.",
            calc: true,
        },
        {
            work_en: "Testing for errors",
            work_ru: "Тестирование на ошибки",
            txt_en: "We will perform a full cycle of testing the layout and code of your project on all possible devices. Diagnostics will be carried out from the client, admin and server sides of the project interaction. A detailed report will be provided on any errors found.",
            txt_ru: "Выполним полный цикл тестирования вёрстки и кода вашего проекта на всех возможных устройствах. Диагностика будет проводится с клиентской, админской и серверной сторон взаимодействия проекта. По найденным ошибкам будет предоставлен подробный отчёт.",
            calc: true,
        },
    ],
}
const pgSrv_diagnosticAdd_1 = {
    work_en: "Promotion measures analytics",
    work_ru: "Аналитика мер продвижения",
    txt_en: "We will analyze your ASO/SEO, advertising campaign and customer interaction with your website. The purpose of this diagnostic is to reduce the time and costs of project promotion. We will find weaknesses and provide you with a detailed report and options for improvement.",
    txt_ru: "Мы проведём анализ вашей ASO/SEO, рекламной кампании и взаимодействие клиентов с вашим сайтом. Целью данной диагностики является сокращение времени и расходов на продвижение проекта. Найдём слабые места и предоставим вам детальный отчёт и варианты по улучшению.",
    calc: true,
}
// сборка
let pgSrv_diagnosticBig_1 = structuredClone(pgSrv_diagnostic)
pgSrv_diagnosticBig_1.works.push(pgSrv_diagnosticAdd_1)

// повторяющаяся ПРОДВИЖЕНИЕ
const pgSrv_promotion = {
    cat_en: "Promotion",
    cat_ru: "Раскрутка",
    works: [
        {
            work_en: "Advertising campaign in Search engines",
            work_ru: "Рекламная кампания в поисковиках",
            txt_en: "Advertising will be displayed in Google or Yandex, in the first positions relative to other search results. Payment can be made both for impressions and for a click specifically on your advertisement. You can edit the cost of display and keywords yourself.",
            txt_ru: "Реклама будет отображаться в Google или Яндекс, на первых позициях относительно остальных результатов поиска. Оплата может происходить как за показы, так и за клик конкретно на вашу рекламу. Стоимость показа и ключевые слова вы сможете редактировать сами.",
            calc: true,
        },
        {
            work_en: "Advertising campaign in Social media",
            work_ru: "Рекламная кампания в соцсетях",
            txt_en: "All popular social networks have their own advertising services. Each of them has its own characteristics. We'll tell you what's best for you, set everything up, install analytics tools and create video training on how to use it.",
            txt_ru: "Все популярные соц сети имеют собственные рекламные сервисы. Каждый из них имеет свои особенности. Подскажем что лучше подойдёт именно вам, всё настроим, установим инструменты аналитики и создадим видео обучение как пользоваться.",
            calc: true,
        },
        {
            work_en: "Email and SMS mailing",
            work_ru: "Email и SMS рассылки",
            txt_en: "Is it difficult to target advertising for your services? If it's very expensive to display one advertisement on the Internet? Then you need to reach as large an audience as possible with minimal costs - SMS and Email newsletters are best suited for this.",
            txt_ru: "Рекламу с вашими услугами сложно таргетировать? Очень дорого обходится показ одной рекламы в интернете? Тогда нужно охватить как можно большую аудиторию с минимальными затратами - лучше всего для этого подходят SMS и Email рассылки.",
            calc: true,
        },
        {
            work_en: "Outdoor advertising",
            work_ru: "Наружная реклама",
            txt_en: "Your business exists not only on the Internet. It is often necessary to prepare decent materials for printing. For example: advertising banners, business cards, brochures, signs, branded items, etc. All you have to do is take the files to a printing office convenient for you.",
            txt_ru: "Ваш бизнес существует не только в интернете. Часто необходимо подготовить достойные материалы для полиграфии. Например: рекламные баннеры, визитки, брошюры, вывески, фирменные вещи и т.п. Вам останется лишь отнести файлы в удобную для вас полиграфию.",
            calc: true,
        },
        {
            work_en: "Promotion of positions in search engines",
            work_ru: "Продвижение позиций в поисковках",
            txt_en: "Do you want to raise your product to the top of search engines or marketplace? Then it is necessary to carry out SEO improvements to your project so that it meets the numerous requirements set by search engines.",
            txt_ru: "Вы хотите поднять ваш продукт в топ поисковых систем или маркетплейса? Тогда необходимо провести SEO улучшения вашего проекта, чтобы он соответствовал многочисленным требованиям выставляемым поисковыми системами.",
            calc: true,
        },
    ],
}

// повторяющаяся ВЕДЕНИЕ
const pgSrv_management = {
    cat_en: "Management",
    cat_ru: "Ведение",
    works: [
        {
            work_en: "Filling and updating content",
            work_ru: "Наполнение и обновление контента",
            txt_en: "Often, clients do not have time to constantly create and update content of a decent level. Texts, illustrations, videos and design of published content - you can forget about all these everyday hassles.",
            txt_ru: "Зачастую у клиентов нет времени чтобы перманентно заниматься созданием и обновлением контента достойного уровня. Тексты, иллюстрации, видео и дизайн публикуемого контента - вы можете забыть о всех этих повседневных хлопотах.",
            calc: true,
        },
        {
            work_en: "Advertising campaign management",
            work_ru: "Ведение рекламной кампании",
            txt_en: "It’s one thing to set up and launch an advertising campaign, and quite another to carry out permanent work on analyzing advertising and improving it. “Google Analytics”, “Yandex Metrica”, “Social Network Pixels” and much more - we will take over and provide monthly reports!",
            txt_ru: "Одно дело настроить и запустить рекламную кампанию, а совсем другое вести перманентную работу по анализу рекламы и её улучшению. «Google Аналитика», «Яндекс Метрика», «Пиксели соцсетей» и многое другое - мы возьмём на себя и будем предоставлять ежемесячные отчёты!",
            calc: true,
        },
        {
            work_en: "Technical support",
            work_ru: "Техническая поддержка",
            txt_en: "Something broke? Need help with your work? Need to update plugins and languages? Make backups? You don't need to understand technical issues and waste your time. Just call us - technical support 24/7.",
            txt_ru: "Что-то сломалось? Нужна помощь в работе? Необходимо обновлять плагины и языки? Делать бэкапы? Вам не нужно разбираться в технических вопросах и тратить своё время. Достаточно позвонить нам - техподдержка 24/7.",
            calc: true,
        },
    ],
}

export const pg_Services = [
    {   id: 0,
        name_en: "Mob. Apps",
        name_ru: "Приложения",
        de1_ru: "Автоматизация бизнеса, Кабинеты, Каталоги, Рейтинги,",
        de1_en: "Business Automation, Accounts, Catalogs, Ratings,",
        de2_ru: "Сервисы бронирования, Доставки, Обучения ...",
        de2_en: "Reservation services, Delivers, Trainings ...",
        cats: [
            {
                cat_en: "Creation & Publishing",
                cat_ru: "Создание и Публикация",
                works: [
                    {
                        work_en: "Create application for Android",
                        work_ru: "Создать приложение для Android",
                        txt_en: "The application will be written in Java or Kotlin strictly for Android devices. This will allow you to use all the features of this OS, increase your ranking in Google Play and improve performance compared to cross-platform solutions.",
                        txt_ru: "Приложение будет написано на Java или Kotlin строго для устройств Android. Это позволит вам использовать все возможности данной ОС, повысит рейтинг в Google Play и улучшит производительность в сравнении с кроссплатформенными решениями.",
                        calc: true,
                    },
                    {
                        work_en: "Create application for iOS",
                        work_ru: "Создать приложение для iOS",
                        txt_en: "The application will be written in Swift or Objective-C strictly for iOS devices. This will allow you to use all the features of this OS, increase your Apple Store rating and improve performance compared to cross-platform solutions.",
                        txt_ru: "Приложение будет написано на Swift или на Objective-C строго для устройств iOS. Это позволит вам использовать все возможности данной ОС, повысить рейтинг Apple Store и улучшит производительность в сравнении с кроссплатформенными решениями.",
                        calc: true,
                    },
                    {
                        work_en: "Create Cross-platform application",
                        work_ru: "Создать Кроссплатформенное приложение",
                        txt_en: "Applications written in C# or created in Cordova can be published on any platform: iOS, Android, Linux, Windows. It's faster and cheaper than creating + maintaining several applications for each OS. But be aware that this approach entails a decrease in the quality of the product.",
                        txt_ru: "Написанные на языке C# или созданные в Cordova - приложения можно будет публиковать на любой платформе: iOs, Android, Linux, Windows. Это быстрее и дешевле чем создавать + поддерживать несколько приложений по каждую ОС. Но знайте, что такой подход влечет снижение качества продукта.",
                        calc: true,
                    },
                    {
                        work_en: "Create Cross-platform in online builder",
                        work_ru: "Создать Кроссплатформенное в онлайн конструкторе",
                        txt_en: "Creating an application in an online designer is the cheapest and fastest way. But this option is fraught with many limitations and disadvantages. Suitable for simple applications with minimal functionality, such as: Catalogs, Ratings, Trainings and other information applications.",
                        txt_ru: "Создание приложения в онлайн конструкторе - самый дешевый и быстрый способ. Но этот вариант таит множество ограничений и недостатков. Подходит для простых приложений с минимальным функционалом, таких как: Каталоги, Рейтинги, Тренинги и другие информационные приложения.",
                        calc: true,
                    },
                    {
                        work_en: "Publishing in stores",
                        work_ru: "Публикация в маркетплейсах",
                        txt_en: "It is not enough to create an application, you also need to publish it on the store(s). We will take into account all the requirements, features of each of the stores, and carry out all bureaucratic procedures. We will create and fill the application account(s) with relevant content.",
                        txt_ru: "Не достаточно создать приложение, его нужно еще опубликовать на маркетплейсе(ах). Учтем все требования, особенности каждого из маркетплейсов, и проведем все бюрократические процедуры. Создадим и наполним аккаунт(ы)приложения релевантным контент.",
                        calc: true,
                    },
                ],
            },
            pgSrv_improvementBig_1,
            pgSrv_diagnosticBig_1,
            pgSrv_promotion,
            pgSrv_management,
        ],
    },
    {   id: 1,
        name_en: "Web Sites",
        name_ru: "Веб Сайты",
        de1_ru: "Интернет магазины, Сервисы, Портфолио, Стоки,",
        de1_en: "Online Stores, Services, Portfolios, Stocks, Aggregators,",
        de2_ru: "Агрегаторы, Соцсети, Банкинги, Биржи ...",
        de2_en: "Social Networks, Banking, Exchanges ...",
        cats: [
            {
                cat_en: "Create a new site",
                cat_ru: "Создание нового",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Server work",
                cat_ru: "Серверные работы",
                works: [
                    {
                        work_en: "Moving to another server",
                        work_ru: "Переезд на другой сервер",
                        txt_en: "We will transfer / copy your website to another hosting (server) and, of course, solve all problems that arise along the way. Also, if necessary, we will help with choosing a server for your project.",
                        txt_ru: "Мы перенесём / копируем ваш сайт на другой хостинг (сервер) и конечно же решим все попутно возникающие задачи. Также в случае необходимости поможем с выбором сервера для вашего проекта.",
                        calc: true,
                    },
                    {
                        work_en: "Working with domains",
                        work_ru: "Работы с доменами",
                        txt_en: "Do you need to change your website domain? Sorting out confusion about certificates? Link the work of several domains / subdomains / hostings? Set up redirects? We will help and of course give recommendations if necessary.",
                        txt_ru: "Вам нужно сменить домен сайта? Разобраться с путаницей в сертификатах? Связать работу нескольких доменов / поддоменов / хостингов? Настроить переадресации? Поможем и конечно дадим рекомендации если понадобится.",
                        calc: true,
                    },
                    {
                        work_en: "Switching to versioning (GIT)",
                        work_ru: "Переход на работу с версиями (GIT)",
                        txt_en: "Do you have several developers working on your project at once? Then the GIT version control system will help you a lot. You will see when, what and by whom changes were made. You can roll back individual changes. The project will be protected from accidental or intentional damage by developers.",
                        txt_ru: "Над вашем проектом работает сразу несколько разработчиков? Тогда вам очень поможет система контроля версий GIT. Вы будите видеть когда, какие и кем были сделаны изменения. Сможете откатывать отдельные изменения. Проект будет защищён от случайной или намеренной порчи со стороны разработчиков.",
                        calc: true,
                    },
                    {
                        work_en: "Mail servers setup",
                        work_ru: "Настройка почтовых серверов",
                        txt_en: "Need to install or configure a mail server? Implement automatic replies and newsletters from the site? Make it possible to log in to a website using popular email services and social networks? We do all this with our eyes closed :)",
                        txt_ru: "Нужно установить или настроить работу почтового сервера? Реализовать автоматические ответы и рассылки с сайта? Сделать возможным авторизацию на сайте при помощи популярных почтовых сервисов и соц сетей? Всё это мы делаем с закрытыми глазами :)",
                        calc: true,
                    },
                    {
                        work_en: "Setting up any API",
                        work_ru: "Настройка любого API",
                        txt_en: "We will connect the operation of your website with any services that have an API. If you need third-party services to be able to interact with your site themselves, then we will create your own API for any task.",
                        txt_ru: "Свяжем работу вашего сайта с любыми сервисами имеющими API. Если же вам нужно чтобы сторонние сервисы могли сами взаимодействовать с ваши сайтом, то мы создадим вам собственный API под любые задачи.",
                        calc: true,
                    },
                ],
            },
            pgSrv_improvementBig_2,
            pgSrv_diagnosticBig_1,
            pgSrv_promotion,
            pgSrv_management,
        ],
    },
    {   id: 2,
        name_en: "Soft",
        name_ru: "Софт",
        de1_ru: "ПО для Панелей, Смарт TV, Смарт часов, Соц.сетей,",
        de1_en: "Software for Panels, Smart TV, Smart Watches, Social net`s,",
        de2_ru: "Драйвера, Виртуальная и Доп. реальность ...",
        de2_en: "Drivers, Virtual and Augmented reality ...",
        cats: [
            {
                cat_en: "Creating a new program",
                cat_ru: "Создание нового",
                works: [
                    {
                        work_en: "Create program for Windows",
                        work_ru: "Создать программу под Windows",
                        txt_en: "The program will be written in C# or Microsoft Visual Basic. This will allow you to use all the features of Windows OS as efficiently as possible.",
                        txt_ru: "Программа будет написана на C# или на Microsoft Visual Basic. Это позволит использовать максимально эффективно все возможности ОС Windows.",
                        calc: true,
                    },
                    {
                        work_en: "Create program for Mac OS",
                        work_ru: "Создать программу под Mac OS",
                        txt_en: "The program will be written in Swift or Objective-C. This will allow you to make the most of all the features of the Mac OS.",
                        txt_ru: "Программа будет написана на Swift или на Objective-C. Это позволит использовать максимально эффективно все возможности ОС Mac.",
                        calc: true,
                    },
                    {
                        work_en: "Create program for SmartTV / Android",
                        work_ru: "Создать программу под SmartTV / Android",
                        txt_en: "The program will be written using Smartbox libraries. This will allow the program to be cross-platform and work well on displays of any manufacturer.",
                        txt_ru: "Программа будет написана с использованием библиотек Smartbox. Это позволит программе быть кроссплатформенной и хорошо работать на дисплеях любой фирмы производителя.",
                        calc: true,
                    },
                ],
            },
            pgSrv_improvement,
            pgSrv_diagnostic,
            pgSrv_promotion,
            pgSrv_management,
        ],
    },
    {   id: 3,
        name_en: "Graphics",
        name_ru: "Графика",
        de1_ru: "3D товары / объекты, Инфографика услуг, Визитки,",
        de1_en: "3D Products/objects, Service infographics, Business cards,",
        de2_ru: "Анимированная реклама, Переход на вектор ...",
        de2_en: "Animated advertisement, Transition to vector graphic ...",
        cats: [
            {
                cat_en: "Branding",
                cat_ru: "Брендирование",
                works: [
                    {
                        work_en: "Logo / Icons / Stamp",
                        work_ru: "Логотип / Иконки / Печать",
                        txt_en: "A symbol of your business. Acts as advertising for new clients and recognition for old ones as an indicator of solving their problems. It is difficult to underestimate its importance! What should it be like? We will help you with ideas and implementation.",
                        txt_ru: "Символ вашего дела. Выполняет роль рекламы для новых клиентов и узнаваемости для старых как индикатор решения их задач. Сложно недооценить его важность! Каким он должен быть? Мы поможем вам с идеями и реализацией.",
                        calc: true,
                    },
                    {
                        work_en: "Everything for Printing",
                        work_ru: "Всё для Полиграфии",
                        txt_en: "Before going to a printing house to print a brochure, business card, banner or anything else, it is important to have decent source files and knowledge of what and how to print. If you turn to printing house employees to create materials, you run the risk of getting something primitive.",
                        txt_ru: "Прежде чем идти в типографию печать брошюры, визитки, баннера или что то ещё - важно иметь при себе достойные исходные файлы и знания что, как печатать. Обратившись за созданием материалов к сотрудникам типографии, вы рискуете получить примитив.",
                        calc: true,
                    },
                    {
                        work_en: "Corporate identity / Brand book",
                        work_ru: "Фирстиль / Брендбук",
                        txt_en: "Appearance is an important part of a company's image. Brand colors, signage, design elements, clothing, fonts, furniture and much more. A brand book is a set of rules and best practices for designers from different fields when working with the appearance of your company.",
                        txt_ru: "Внешний вид это важная часть имиджа компании. Фирменные цвета, вывески, элементы оформления, одежда, шрифты, мебель и многое другое. Брендбук - это свод правил и наработок для дизайнеров из разных сфер при работе с внешним видом вашей компании.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Photo / Video editing",
                cat_ru: "Фото / Видеомонтаж",
                works: [
                    {
                        work_en: "Processing and Editing of photos",
                        work_ru: "Обработка и Монтаж фотографий",
                        txt_en: "These works are most often carried out at the design stages of a future project or filling the project with content - when selected photographs and pictures need to be brought into proper form before being published for public viewing.",
                        txt_ru: "Данные работы чаще всего выполняются на этапах дизайна будущего проекта или наполнения проекта контентом - когда отобранные фотографии и картинки нужно привести в подобающий вид перед публикацией на общее обозрение.",
                        calc: true,
                    },
                    {
                        work_en: "Video Editing, Overlay effects",
                        work_ru: "Видеомонтаж, Наложение эффектов",
                        txt_en: "These works are most often performed with videos shot for an advertising campaign, as well as at the stage of filling the project with content - when the filmed video needs to be finalized or simply brought into proper form before publishing it for public viewing.",
                        txt_ru: "Данные работы чаще всего выполняются с видео снятыми для рекламной кампании, а также на этапе наполнения проекта контентом - когда снятое видео нужно доработать или просто привести в подобающий вид перед публикацией на общее обозрение.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Illustrations",
                cat_ru: "Иллюстрации",
                works: [
                    {
                        work_en: "Vector drawing",
                        work_ru: "Векторная отрисовка",
                        txt_en: "Vector illustrations differ from raster ones in their very small file size. This is very important when working with online projects because download speed should be high. But there is also a flip side to the coin - vector images are more difficult to create.",
                        txt_ru: "Векторные иллюстрации отличаются от растровых очень маленьким размером файлов. Это очень важно при работе с онлайн проектами т.к. скорость загрузки должна быть высокой. Но есть и обратная сторона медали - векторные изображения сложнее в создании.",
                        calc: true,
                    },
                    {
                        work_en: "Raster solutions",
                        work_ru: "Растровые решения",
                        txt_en: "We will create an illustration of any complexity - cartoon, photorealistic, oil style, futuristic... any! For screens or for printing. At the end of the work, you will have PNG, JPG, PDF or any other high-resolution raster format.",
                        txt_ru: "Создадим иллюстрацию любой сложности - мультяшную, фотореалистичную, в стиле маслом, футуристичную ... любую! Для экранов или для печати. В конце работы у вас будут PNG, JPG, PDF или любой другой растровый формат в высоком разрешении.",
                        calc: true,
                    },
                    {
                        work_en: "3D visualization",
                        work_ru: "3D визуализация",
                        txt_en: "3D comes to the rescue when you need to create accurate visualizations of products, drawings, objects... anything! Having created the model once, you can generate its images from different angles as much as you want. It is also possible to insert 3D into a project and clients will be able to rotate it in real time.",
                        txt_ru: "3D спешит на помощь когда вам нужно создать точную визуализацию товаров, чертежей, объектов ... чего угодно!  Создав модель единожды можно генерить её изображения с разных ракурсов, сколько захочется. Также можно вставить 3D в проект и клиенты смогут вращать её в реальном времени.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Animations",
                cat_ru: "Анимации",
                works: [
                    {
                        work_en: "Motion design / Vector animation",
                        work_ru: "Моушн дизайн / Векторная анимация",
                        txt_en: "There are many ways to create 2D animation in the modern world. For example: animated banners, or cartoons as «Simsons», or overlaying interactive elements on top of videos. The style and detailing of objects can be different: cartoonish, strict, technical, vintage... any.",
                        txt_ru: "Существует множество способов создать 2Д анимацию в современном мире. Например анимированные баннеры или мультики аля «Симпсоны» или наложение интерактивных элементов поверх видео. Стилистика и детализация объектов может быть разной: мультяшной, строгой, технической, винтажной ... любой.",
                        calc: true,
                    },
                    {
                        work_en: "3D animation",
                        work_ru: "3D анимация",
                        txt_en: "Modern 3D animation has no limitations - any idea can be realized. And the main beauty is that it can be superimposed on a video and get realistic augmented reality. Let it be objects, space around or even characters.",
                        txt_ru: "Современная 3D анимация не имеет ограничений, воплотить можно любую идею. А главная прелесть в том что её можно наложить на видео и получить реалистичную дополненную реальность. Пусть то будет объекты, пространство вокруг или даже персонажи.",
                        calc: true,
                    },
                ],
            },
        ],
    },
]

export const pg_Services2 = [
    {  id:0,
        en: "Services range",
        ru: "Спектр услуг",
    },
    {  id:1,
        en: "Calculate cost",
        ru: "Посчитать цену",
    },
    {  id:2,
        en: "Ask a question",
        ru: "Задать вопрос",
    },
    {  id:3,
        en: "I have a question about",
        ru: "У меня есть вопрос о",
    },
]






















