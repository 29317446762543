console.log('text_pg_Reviews')

export const pg_Reviews1 = [
    {   id: 0,
        en: "Feedback from our clients",
        ru: "Отзывы наших клиентов",
    },
    {   id: 1,
        en: "All our clients come to us based on someone’s recommendations. There is no more reliable advertising than word of mouth - that’s why we don’t spend money on advertising, but make sure that clients are satisfied with the work done. This section contains reviews from some of our clients. If you want to contact a client whose review is not on this list, we can easily provide you with his contact information. You can also leave your review by scrolling down to the bottom of the section.",
        ru: "Все наши клиенты приходят к нам по чьим то рекомендациям. Нет надёжнее рекламы чем сарафанное радио - поэтому мы не тратимся на рекламу, а заботимся о том чтобы клиенты оставались довольны проделанной работой. В данном разделе собраны отзывы некоторых наших клиентов. Если вы хотите связаться с клиентом чей отзыв отсутствует в этом списке, то мы легко предоставим вам его контакты. Также вы можете оставить свой отзыв, опустившись вниз раздела.",
    },
    {   id: 2,
        en: "I would like to leave my feedback",
        ru: "Хочу оставить свой отзыв",
    },
]

export const pg_Reviews2 = [
    {   id: 0,
        photo: "welcome.jpg",
        name_en: "Mchedlidze Alexey",
        name_ru: "Мчедлидзе Алексей",
        role_en: "Agency owner",
        role_ru: "Владелец агентства",
        txt_en: "This is not the first time I ordered a website from Roman and I will contact you again! It’s very convenient for me that by turning to one, and not several specialists, I can get high-quality design and functionality of any complexity. At all stages there is clear and transparent interaction, as well as very reasonable prices.",
        txt_ru: "Заказывал сайт у Романа не в первый раз и обращусь еще! Мне очень удобно, что обратившись к одному, а не к нескольким специалистам удается получить и качественный дизайн и функционал любой сложности. На всех этапах четкое и прозрачное взаимодействие, а также очень адекватные цены.",
        logo: "welcome_ico.png",
        project_en: "WelcomeGeo",
        project_ru: "WelcomeGeo",
        link: "welcomegeo.com",
        url: "https://welcomegeo.com/objects?map=yes",
    },
    {   id: 1,
        photo: "capper.jpg",
        name_en: "Lunev Vyacheslav",
        name_ru: "Лунев Вячислав",
        role_en: "Investor",
        role_ru: "Инвестор",
        txt_en: "We ordered a website for sports analytics at the Roman studio. I was pleased with the result, as everything was done quickly and taking into account all my wishes. I also want to note that I received valuable advice and several ideas for the site that had not occurred to me. I recommend it to everyone and will contact this studio again in future work.",
        txt_ru: "Заказали сайт для спортивной аналитики в студии Роман. Остался доволен результатом, так как все сделали быстро и с учетом всех моих пожеланий. Также хочу отметить, что получил ценные советы и несколько идей для сайта, которые мне не пришли в голову. Всем рекомендую и снова обращусь в эту студию при дальнейшей работе.",
        logo: "capper_ico.png",
        project_en: "Capperroom",
        project_ru: "Capperroom",
        link: "capperroom.com",
        url: "https://capperroom.com/en/predictions",
    },
    {   id: 2,
        photo: "kenyx.jpg",
        name_en: "Shegay Alexander",
        name_ru: "Шегай Александр",
        role_en: "General director",
        role_ru: "Генеральный директор",
        txt_en: "I can describe working with Roman as with a person who knows exactly what he’s doing, so it was easy to cooperate. What was even more important was trust and a clear understanding: the money was not wasted and the result will be more than just good!",
        txt_ru: "Работу с Романом могу охарактеризовать, как с человеком точно знающим своё дело, поэтому сотрудничать было легко. Что еще более важное было доверие и чёткое понимание: деньги зря не потрачены и результат будет более, чем просто хорошим!",
        logo: "kenyx_ico.png",
        project_en: "KENYX",
        project_ru: "KENYX",
        link: "zabor-knx.ru",
        url: "https://zabor-knx.ru/otkatnie",
    },
    {   id: 3,
        photo: "space.jpg",
        name_en: "Chimin Oleg",
        name_ru: "Чимин Олег",
        role_en: "Director",
        role_ru: "Директор",
        txt_en: "We hoped for a good result, but the result exceeded all our expectations!!! This is definitely magic! The process was easy and even fun. Roman, thank you and your team, we will definitely come back to you.",
        txt_ru: "Мы надеялись на хороший результат, но результат превзошел все наши ожидания!!! Это точно магия! Процесс был легким и даже увлекательным. Роман, спасибо тебе и твоей команде, мы обязательно к вам ещё вернёмся.",
        logo: "space_ico.png",
        project_en: "Space Moving",
        project_ru: "Space Moving",
        link: "spacemoving.net",
        url: "https://spacemoving.net",
    },
    {   id: 4,
        photo: "eliturk.jpg",
        name_en: "Irem Erdinch",
        name_ru: "Ирем Эрдинч",
        role_en: "General director",
        role_ru: "Генеральный директор",
        txt_en: "We would like to express our gratitude to Roman Samborsky for his unique work, punctuality, correctness and great respect for the projects and their customers! We warmly recommend Roman as an experienced, multidisciplinary specialist, competent in the full cycle of work on creating and promoting strong, well-thought-out, easy-to-use websites. With respect and gratitude, Director and founder of ELITURK Irem Erdinc.",
        txt_ru: "Хотим выразить благодарность Роману Самборскому за уникальную работу, пунктуальность, корректность и большое уважение к проектам и их заказчикам! Горячо рекомендуем Романа как опытного специалиста широкого профиля, компетентного в полном цикле работ по созданию и продвижению сильных, продуманных, удобнейших в работе сайтов. С уважением и благодарностью, директор и учредитель компании ELITURK Ирем Эрдинч.",
        logo: "eliturk_ico.png",
        project_en: "Eliturk",
        project_ru: "Eliturk",
        link: "eliturk.ru",
        url: "https://eliturk.ru",
    },
    {   id: 5,
        photo: "mars.jpg",
        name_en: "Hasan Cihat Baybagan",
        name_ru: "Хасан Чихат Байбаган",
        role_en: "Director",
        role_ru: "Директор",
        txt_en: "Dear Roman, Thanks for the great work in our website design. Thanks to you, we have had the practical website we wished for. And I appreciate that you still give us ideas to make it better and you're there whenever we need support and some improvements. Wish you all the best, buddy. Hasan, GM, Mars Construction.",
        txt_ru: "Уважаемый Роман, Спасибо за прекрасную работу по дизайну нашего сайта. Благодаря вам у нас появился практичный веб-сайт, о котором мы мечтали. И я ценю, что вы по-прежнему даете нам идеи, как сделать его лучше, и вы всегда рядом, когда нам нужна поддержка и некоторые улучшения. Желаю тебе всего наилучшего, приятель. Хасан, генеральный директор Mars Construction.",
        logo: "mars_ico.png",
        project_en: "MARS construction",
        project_ru: "MARS construction",
        link: "marsalanya.com",
        url: "https://marsalanya.com/objects",
    },
    {   id: 6,
        photo: "nophoto.jpg",
        name_en: "Zimin Artur",
        name_ru: "Зимин Артур",
        role_en: "Frontend development team lead",
        role_ru: "Тимлид фронтенд разработки",
        txt_en: "Thank you very much to you and your team, Roman! We really like the design you created. Usability is excellent.",
        txt_ru: "Спасибо большое вам и вашей команде, Роман! Разработанный вами дизайн нам очень нравится. Юзабилити на высоте.",
        logo: "auto3n_ico.png",
        project_en: "Auto3N",
        project_ru: "Auto3N",
        link: "auto3n.com",
        url: "https://auto3n.ru/batch-catalogs",
    },
    {   id: 7,
        photo: "ilk.jpg",
        name_en: "Alena Grib",
        name_ru: "Алёна Гриб",
        role_en: "Project manager",
        role_ru: "Руководитель проектов",
        txt_en: "Our company has been working with Roman for 3 years, we are very pleased with the cooperation! It is very pleasant to work with Roman, he has an excellent sense of taste, high professionalism, he is not indifferent to his work and helps to implement all projects at the highest level.",
        txt_ru: "Наша компания работает с Романом уже 3 года, мы очень довольны сотрудничеством! С Романом работать очень приятно, у него прекрасное чувство вкуса, высокий профессионализм, он неравнодушно относится к работе и помогает все проекты реализовать на высшем уровне.",
        logo: "ilk_ico.png",
        project_en: "ILK drones",
        project_ru: "ИЛК беспилотники",
        link: "ilk.aero",
        url: "https://ilk.aero",
    },
    {   id: 8,
        photo: "gpp54.jpg",
        name_en: "Kuzhelev Roman",
        name_ru: "Кужелев Роман",
        role_en: "Director",
        role_ru: "Директор",
        txt_en: "We have been working with Roman for many years, he always solves the assigned problems. Formalism and careless work are NOT about him. The last thing that impressed me was how professionally he “dealt with” the problem that arose in the payment system on the site. He literally “taught” the service provider’s specialists how their own system works.",
        txt_ru: "Работаем с Романом много лет, всегда решает поставленные задачи. Формализм и работа «спустя рукава» это НЕ про него. Последнее что меня впечатлило, как он профессионально «разобрался» с возникшей проблемой в платежной системе на сайте. Он буквально «научил» специалистов поставщика услуг, как работает их же система.",
        logo: "gpp54_ico.png",
        project_en: "Geo Plus",
        project_ru: "Гео Плюс",
        link: "gpp54.ru",
        url: "https://gpp54.ru",
    },
    {   id: 9,
        photo: "nophoto.jpg",
        name_en: "Minakhutdin Roman",
        name_ru: "Минахутдин Роман",
        role_en: "Director",
        role_ru: "Директор",
        txt_en: "I’ve been working with Roma for many years, I made my first website with him back when he was only doing design. Since then, I turn only to him with every new idea I have. He always tries to understand the essence of my task, which really surprises me - the result is the most important thing for him!",
        txt_ru: "Работаю с Ромой уже много лет, делал с ним первый сайт еще когда он занимался только дизайном. С тех пор с каждой своей новой идеей обращаюсь только к нему. Он всегда пытается понять суть моей задачи, чем он меня реально удивляет - результат для него самое важное!",
        logo: "gmz_ico.png",
        project_en: "Where's My Land",
        project_ru: "Где Моя Земля",
        link: "гдеземля.рф",
        url: "https://гдеземля.рф/",
    },
]






























