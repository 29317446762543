import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../context/Context.js';

const CalculatorPg = () => { console.log('CalculatorPg')
    // states and vars
    const {lang, setAppFirstLoad} = useContext(AuthContext)

    // loading
    useEffect(() => {
        setAppFirstLoad(false)
    }, [setAppFirstLoad])

    return (
        <div className='page page-calculator'>
            <h1>
                { lang === 'en'
                    ? "The section is under development. Come back in a couple of weeks :)"
                    : "Раздел находится в разработке. Приходите через пару недель :)"
                }
            </h1>
        </div>
    );
};

export default CalculatorPg;