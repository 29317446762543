import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../context/Context.js';

const FilesPg = () => { console.log('FilesPg')
    // states and vars
    const {lang, setAppFirstLoad} = useContext(AuthContext)

    // loading
    useEffect(() => {
        setAppFirstLoad(false)
    }, [setAppFirstLoad])

    return (
        <div className='page page-files'>
            {/*<h1>Документы и Файлы</h1>*/}
            {/*<h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad adipisci architecto consequuntur cum cumque deleniti dolores eaque est excepturi id ipsam, ipsum, libero maiores nam neque nihil odio officia officiis perferendis quisquam recusandae sint sit sunt ut voluptas? A amet doloremque, eveniet fugiat fugit hic illo ipsa, maxime recusandae tempora veniam veritatis voluptates? Distinctio earum est fugiat ipsa itaque laborum numquam quasi quo similique veniam. Accusantium architecto culpa ducimus ex itaque iure minima odit optio placeat quae qui quis, quos recusandae sapiente sit soluta totam. Accusamus eius illo officiis qui quia velit voluptas! Doloribus eaque expedita ipsum molestiae non optio.</h2>*/}
            {/*<div className='page-files-wrp'>*/}
            {/*    <div className="box box-folder"> </div>*/}
            {/*</div>*/}
            <h1>
                { lang === 'en'
                    ? "The section is under development. Come back in a couple of weeks :)"
                    : "Раздел находится в разработке. Приходите через пару недель :)"
                }
            </h1>
        </div>
    );
};

export default FilesPg;