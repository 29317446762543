import React, {useContext, useEffect, useRef, useState} from 'react'
import {NavLink} from "react-router-dom"
import {AuthContext} from '../context/Context.js'
import {publicRoutes} from "../context/AppRoutes"
import {basicText} from "../context/BasicInterfaceText"
import {changeLang} from "../functions/changeLang";
import logoEn from '../img/logo_en.svg'
import logoRu from '../img/logo_ru.svg'
import img1 from "../img/i_ocenka.svg";
import img22 from "../img/i_zarabotat_en.svg";
import img21 from "../img/i_zarabotat.svg";
import img3 from "../img/i_skidka.svg";
import img42 from "../img/i_oplatit_en.svg";
import img41 from "../img/i_oplatit.svg";
import img5 from "../img/i_mail.svg";

const MainMenu = () => { console.log('MainMenu')
    // context & states
    const {lang, setLang, isLandscape, isLaptop, setPops, menuPage, setMenuPage} = useContext(AuthContext)
    const [mobMenu, setMobMenu] = useState(false)
    const [loaded, setLoaded] = useState({
        p1: false,
        p2: false,
        p3: false,
        p4: false,
        p5: false,
    })

    // refs
    const menuWrp = useRef()
    const menuRights = useRef()
    const menuEls = useRef([])
    const menuLng1 = useRef()
    const menuLng2 = useRef()

    // loading
    useEffect(() => {
        if(isLandscape){
            let m1 = "4.2vw"
            if(isLaptop){
                m1 = "4.8vw"
            }

            setTimeout(function (){
                menuWrp.current.style.width = '16.5vw'
            }, 400) // выдвигаем меню
            setTimeout(function (){
                menuEls.current.map((el, i) => {
                    if(i === 0){ // если логотип
                        el.style.display = 'block'
                        setTimeout(function () {
                            el.style.opacity = '1'
                            el.style.height = m1
                        }, 100)
                        setTimeout(function () {
                            el.firstElementChild.style.opacity = '1'
                            el.firstElementChild.style.marginLeft = '2.15vw'
                        }, 600)
                    }else{ // если простые пункты меню
                        setTimeout(function () {
                            el.style.display = 'block'
                        }, 500+300*i)
                        setTimeout(function () {
                            el.style.opacity = '1'
                            el.style.paddingLeft = '2vw'
                        }, 600+300*i)
                    }
                    return ''
                })
            }, 1200) // появляются пункты меню
            setTimeout(function (){
                menuRights.current.style.display = 'block'
                setTimeout(function (){
                    menuRights.current.style.opacity = '1'
                }, 100)
            }, 4800) // появление копирайта
        }else{
            setTimeout(function (){
                setLoaded({p1: true,p2: false,p3: false,p4: false,p5: false})
                setTimeout(function (){
                    setLoaded({p1: true,p2: true,p3: false,p4: false,p5: false})
                }, 400)
                setTimeout(function (){
                    setLoaded({p1: true,p2: true,p3: true,p4: false,p5: false})
                }, 700)
                setTimeout(function (){
                    setLoaded({p1: true,p2: true,p3: true,p4: true,p5: false})
                }, 1000)
                setTimeout(function (){
                    setLoaded({p1: true,p2: true,p3: true,p4: true,p5: true})
                }, 1500)
                setTimeout(function (){
                    menuLng1.current.style.animationDuration = '0s'
                    menuLng2.current.style.animationDuration = '0s'
                }, 2000)
                setTimeout(function (){
                    menuWrp.current.style.transitionTimingFunction = 'cubic-bezier(0,.7,.42,1)'
                }, 1000)
            }, 300) // выдвигаем меню
        }
    }, [isLandscape, isLaptop])

    return (
        <div className={`navmenu ${mobMenu ? " navmenu-active" : ""} ${loaded.p1 ? " loaded" : ""}`}
             ref={menuWrp}>


            {!isLandscape ? <div className="navmenu-mob">
                <div className="navmenu-langs">
                    <div ref={menuLng1}
                         className={`navmenu-langs-flag navmenu-langs-flag-ru ${lang === 'ru' ? ' active' : ''} ${loaded.p3 ? " loaded" : ""}`}
                         onClick={() => changeLang('ru', lang, setLang)}
                    > </div>
                    <div ref={menuLng2}
                         className={`navmenu-langs-flag navmenu-langs-flag-en ${lang === 'en' ? ' active' : ''} ${loaded.p4 ? " loaded" : ""}`}
                         onClick={() => changeLang('en', lang, setLang)}
                    > </div>
                </div>
                <div className={`navmenu-burger ${mobMenu ? " navmenu-burger-active " : ""} ${loaded.p5 ? " loaded" : ""}`}
                        onClick={() => {
                            if(mobMenu){
                                setMobMenu(false)
                            }else{
                                setMobMenu(true)
                            }
                        }}>
                    <span className="navmenu-burger-palka1">{}</span>
                    <span className="navmenu-burger-palka2">{}</span>
                    <span className="navmenu-burger-palka3">{}</span>
                    <span className="navmenu-burger-palka4">{}</span>
                </div>
            </div> : ''}
            <div className="navmenu-wrp">
                {publicRoutes.map((route, i) => (
                    route.id !== '404' && route.id !== 'payment-good' && route.id !== 'work'
                        ?
                        <NavLink
                            ref={el => menuEls.current[i] = el}
                            className={
                                route.id === 'main'
                                    ?
                                    `navmenu-logo navmenu-pu ${menuPage === 'main' ? " active " : ""} ${loaded.p2 ? " loaded " : ""}`
                                    :
                                    `navmenu-wrp-li navmenu-pu ${menuPage === route.id ? " active " : ""}`
                                }
                            data-id={route.id}
                            to={route.id === 'main'
                                    ?       lang !== 'en' ? route.path + lang : route.path
                                    :       lang !== 'en' ? route.path + '/' + lang : route.path
                                 }
                            key={route.id}
                            onClick={() => {
                                setMenuPage(route.id)
                                setMobMenu(false)
                            }}
                        >{
                            route.id === 'main'
                            ?
                                <img src={lang === 'en' ? logoEn : logoRu} alt="" className="navmenu-logo-img"/>
                            :
                                route[`name_${lang}`]
                        }</NavLink>
                    : ''
                ))}
            </div>
            {!isLandscape ? <div className="navmenu-pops">
                    <div className="navmenu-pops-item" onClick={() => {
                        setMobMenu(false)
                        setPops({
                            p0: true,
                            p1: true,
                            p2: false,
                            p3: false,
                            p4: false,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })
                    }}>
                        <img src={img1} alt=""/>{basicText[1][lang]}
                    </div>
                    <div className="navmenu-pops-item" onClick={() => {
                        setMobMenu(false)
                        setPops({
                            p0: true,
                            p1: false,
                            p2: true,
                            p3: false,
                            p4: false,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })
                    }}>
                        <img src={lang === 'en' ? img22 : img21} alt=""/>{basicText[2][lang]}
                    </div>
                    <div className="navmenu-pops-item topmenu-pops-discount" onClick={() => {
                        setMobMenu(false)
                        setPops({
                            p0: true,
                            p1: false,
                            p2: false,
                            p3: true,
                            p4: false,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })
                    }}>
                        <img src={img3} alt=""/>{basicText[3][lang]}
                    </div>
                    <div className="navmenu-pops-item" onClick={() => {
                        setMobMenu(false)
                        setPops({
                            p0: true,
                            p1: false,
                            p2: false,
                            p3: false,
                            p4: true,
                            p5: false,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })
                    }}>
                        <img src={lang === 'en' ? img42 : img41} alt=""/>{basicText[4][lang]}
                    </div>
                    <div className="navmenu-pops-item" onClick={() => {
                        setMobMenu(false)
                        setPops({
                            p0: true,
                            p1: false,
                            p2: false,
                            p3: false,
                            p4: false,
                            p5: true,
                            p6: false,
                            p7: false,
                            p8: false,
                            p9: false,
                        })
                    }}>
                        <img src={img5} alt=""/>{basicText[5][lang]}
                    </div>
                </div> : ''}
            {isLandscape ? <div className="navmenu-rights" ref={menuRights}>{basicText[0][lang]}</div> : ''}


        </div>
    )
}

export default MainMenu