import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Reviews1, pg_Reviews2} from "../context/pg_Reviews";

const ReviewsPg = () => { console.log('ReviewsPg')
    // states and variables
    const {lang, isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, setPops} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)

    // refs
    const h1 = useRef()
    const h2 = useRef()
    const h3 = useRef()

    // loading
    useEffect(() => {
        if(pgLoaded){
            console.log('STOPPING LOADING ANIMATION of reviews page')
            return
        }else{
            setPgLoaded(true)
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 600
            mobStartTimeOffset = 600
        }

        if(isLandscape){
            let r1 = '10vw'
            let r2 = '20vw'
            if(isLaptop){
                r1 = '13vw'
                r2 = '25vw'
            }

            setTimeout(function () {
                h1.current.style.opacity = '1'
                setTimeout(function () {
                    h2.current.style.opacity = '1'
                },300)
                setTimeout(function () {
                    document.querySelectorAll('.page-reviews-wrp .feedback').forEach((feedback, i) => {
                        setTimeout(function () {
                            feedback.style.width = r1
                            feedback.style.marginLeft = '0'
                        }, 100*i)
                        setTimeout(function () {
                            feedback.children[0].children[0].style.maxHeight = r2
                            feedback.children[0].children[0].style.opacity = '1'
                            feedback.style.backgroundColor = '#333'
                        }, 600*i+1200)
                        setTimeout(function () {
                            feedback.style.width = '100%'
                            feedback.style.boxShadow = '0 1vw 2vw 0 rgba(0,0,0,0.3)'
                        }, 600*i+2000)
                        setTimeout(function () {
                            feedback.children[1].style.display = 'block'
                        }, 600*i+2700)
                        setTimeout(function () {
                            feedback.children[1].style.maxHeight = r2
                        }, 600*i+2800)
                        setTimeout(function () {
                            feedback.children[1].children[1].style.color = '#ccc'
                        }, 600*i+4500)
                    })
                },600)
                setTimeout(function () {
                    h3.current.style.opacity = '1'
                },3000)
            },descStartTimeOffset)
        }else{
            let r1 = '7vw'
            let r2 = '130vw'
            let r3 = '30.5vw'
            if(isPad){
                r1 = '5vw'
                r2 = '90vw'
                r3 = '23vw'
            }

            setTimeout(function () {
                h1.current.style.opacity = '1'
                setTimeout(function () {
                    h2.current.style.opacity = '1'
                },300)
                setTimeout(function () {
                    document.querySelectorAll('.page-reviews-wrp .feedback').forEach((feedback, i) => {
                        setTimeout(function () {
                            feedback.style.width = "100%"
                            feedback.style.paddingRight = '5vw'
                            feedback.style.paddingLeft = '5vw'
                        }, 150*i)
                        setTimeout(function () {
                            feedback.children[1].style.display = 'block'
                        }, 1000*i+1100)
                        setTimeout(function () {
                            feedback.style.backgroundColor = "#333"
                            feedback.style.paddingTop = r1
                            feedback.style.paddingBottom = r1
                        }, 1000*i+1200)
                        setTimeout(function () {
                            feedback.children[1].children[0].children[1].style.opacity = '1'
                            feedback.children[0].children[0].style.opacity = '1'
                        }, 1000*i+1350)
                        setTimeout(function () {
                            feedback.children[1].style.maxHeight = r2
                            feedback.children[1].children[0].children[1].style.maxHeight = '13vw'
                            feedback.children[0].children[0].style.height = r3
                        }, 1000*i+1500)
                    })
                },600)
                setTimeout(function () {
                    h3.current.style.opacity = '1'
                },3000)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded])

    return (
        <div className='page page-reviews'>
            <h1 ref={h1}>{pg_Reviews1[0][lang]}</h1>
            <h2 ref={h2}>{pg_Reviews1[1][lang]}</h2>
            <div className="page-reviews-wrp">
                {pg_Reviews2.map((feedback, i) => (
                    <div className="feedback" key={feedback.id}>
                        <div className="feedback-photo">
                            <img className="feedback-photo-img" src={`/img/reviews/${feedback['photo']}`} alt=""/>
                        </div>
                        <div className="feedback-content">
                            <div className="feedback-content-top">
                                <div className="feedback-content-top-left">
                                    <strong>{feedback[`name_${lang}`]}</strong>
                                    <span>{feedback[`role_${lang}`]}</span>
                                </div>
                                <img className="feedback-content-top-img" src={`/img/reviews/${feedback['logo']}`} alt=""/>
                                <div className="feedback-content-top-right">
                                    <p>{feedback[`project_${lang}`]}</p>
                                    <a href={feedback[`url`]} target="_blank" rel="noreferrer">{feedback[`link`]}</a>
                                </div>
                            </div>
                            <p className="feedback-txt">{feedback[`txt_${lang}`]}</p>
                        </div>
                    </div>
                ))}
            </div>
            <h3 ref={h3} onClick={() => {
                setPops({
                    p0: true, // popup fon
                    p1: false, // popup - free estimate
                    p2: false, // popup - make money with us
                    p3: false, // popup - discount
                    p4: false, // popup - payment
                    p5: true, // popup - message
                    p6: false, // free variable, you can use it for new popup
                    p7: false, // free variable, you can use it for new popup
                    p8: false, // free variable, you can use it for new popup
                    p9: false, // free variable, you can use it for new popup
                })
            }}>{pg_Reviews1[2][lang]}</h3>
        </div>
    );
};

export default ReviewsPg;