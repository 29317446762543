console.log('BasicText')

export const basicText = [
// --------------------------------------------footer of main menu
    {   id: 0,
        en: 'Creating since 2009',
        ru: 'Создаем с 2009г',
    },
// --------------------------------------------top menu pops
    {   id: 1,
        en: 'Free estimate',
        ru: 'Бесплатная оценка',
    },
    {   id: 2,
        en: 'Make money with us',
        ru: 'Заработай с нами',
    },
    {   id: 3,
        en: 'Wanna discount?',
        ru: 'Хочешь скидку?',
    },
    {   id: 4,
        en: 'Pay for work',
        ru: 'Оплатить',
    },
    {   id: 5,
        en: 'Write to us',
        ru: 'Написать',
    },
// --------------------------------------------top contacts
    {   id: 6,
        data: '79530920272',
    },
    {   id: 7,
        data: 'samborsky_r',
    },
    {   id: 8,
        data: '90 552 549 1840',
    },
    {   id: 9,
        data: "samborsky.r@gmail.com",
    },
// --------------------------------------------footer
    {   id: 10,
        en: "Join, don't forget about us:",
        ru: "Присоединяйтесь, чтобы не забыть:",
    },
// --------------------------------------------popup_1
    {   id: 11,
        en: "How much your order cost?",
        ru: "Сколько стоит ваш заказ?",
    },

    {   id: 12,
        ru: "Расчитайте в онлайн калькуляторе",
        en: "Count in online Calculator",
    },
    {   id: 13,
        ru: "Вы можете сами, не спеша, разобраться из чего формируется финальная цена вашего заказа. Выбрать нужный функционал, сравнить разные методы создания. Результаты ваших расчётов можно будет сохранить в формате .doc файла для дальнейшей работы с ним. Оценка является точной и по ней можно сразу приступать к работе. Начните в разделе",
        en: "You can take your time and figure out what determines the final price of your order. Select the desired functionality, compare different creation methods. The results of your calculations can be saved in .doc file format for further work with it. The estimate is accurate and you can start working on it right away. Start in section",
    },
    {   id: 14,
        ru: "Калькулятор",
        en: "Calculator",
    },

    {   id: 15,
        ru: "Заполнить бриф и сообщить нам",
        en: "Fill out the brief and let us know",
    },
    {   id: 16,
        ru: "Для этого необходимо открыть бриф",
        en: "To do this you need to open a brief",
    },
    {   id: 17,
        ru: "по этой ссылке",
        en: "by this link",
    },
    {   id: 18,
        ru: "- Google Таблица. Заполните его и сообщите нам о готовности. Также вы можете скачать бриф себе на компьютер, заполнить и отправить его нам на почту",
        en: "- Google Sheet. Fill it out and let us know when you are ready. You can also download the brief to your computer, fill it out and send it to us by email",
    },
    {   id: 19,
        ru: "или передать при личной встрече.",
        en: "or give it in person.",
    },

    {   id: 20,
        ru: "При встрече или по телефону",
        en: "In person or by phone",
    },
    {   id: 21,
        ru: "Если же первые два варианта вам не подходят, то мы можем встретиться лично, познакомится и подробно всё обсудить. Это можно сделать в нашем / вашем офисе или же в кафе. Для этого",
        en: "If the first two options do not suit you, then we can meet in person, get acquainted and discuss everything in detail. This can be done in our / your office or in a cafe. For this",
    },
    {   id: 22,
        ru: "напишите нам сообщение",
        en: "write us a message",
    },
    {   id: 23,
        ru: "или позвоните по номеру",
        en: "or call the number",
    },
// --------------------------------------------popup_3
    {   id: 24,
        ru: "Получить скидку? Легко!",
        en: "Get a discount? Easily!",
    },

    {   id: 25,
        ru: "1% - 5% за репост в соцсети",
        en: "1% - 5% for reposting on social networks",
    },
    {   id: 26,
        ru: "Разместите у себя репост/рекламу о нас в любой соцсети. Пост должен находится на 1 месте в течении недели. За каждую 1000 подписчиков у вас мы предоставим 1% скидку, но не более 5%. Например у вас есть 2000 подписчиков в разных сетях + у ваших друзей по 1000 = значит разместив дружно нашу рекламу вы получите 5%. Необходимый текст и картинку для поста вы можете взять в разделе ",
        en: "Repost/advertise about us on any social network. The post should be in 1st place for a week. For every 1000 subscribers you have, we will provide a 1% discount, but not more than 5%. For example, you have 2000 subscribers in different networks + your friends have 1000 each = which means that by placing our advertisement together you will receive 5%. You can get the necessary text and image for the post in the section ",
    },
    {   id: 27,
        ru: "Документы",
        en: "Documents",
    },

    {   id: 28,
        ru: "10% - 15% за повторные заказы",
        en: "10% - 15% for repeat orders",
    },
    {   id: 29,
        ru: "Если вы делали заказ на 8000$ или более - далее любые ваши заказы будут дешевле на 10%. Просто напомните нам об этом после оглашения цены и получите скидку. Данная скидка может суммироваться с скидкой за репост/рекламу и достигать 15%.",
        en: "If you placed an order for $8,000 or more, then any of your orders will be 10% cheaper. Just remind us about this after the price is announced and you will receive a discount. This discount can be combined with a discount for repost/advertising and reach 15%.",
    },

    {   id: 30,
        ru: "До 100% на партнерских условиях",
        en: "Up to 100% on partner terms",
    },
    {   id: 31,
        ru: "Мы бесплатно или значительно дешевле создадим сайт / приложение. Плюс можем заниматься ведением и раскруткой. Это не шутка и без подвохов. Просто если ваш проект покажется нам перспективным и там будут предусмотрены оплаты онлайн, то мы готовы за договорной процент от продаж взять на свои плечи часть или все работы.",
        en: "We will create a website/application for free or much cheaper. Plus we can do management and promotion. This is not a joke and there are no tricks. It’s just that if your project seems promising to us and online payments are provided, then we are ready to take on part or all of the work for an agreed percentage of sales.",
    },
// --------------------------------------------popup_2
    {   id: 32,
        ru: "Заработай с нами",
        en: "Make money with us",
    },
    {   id: 33,
        ru: "Всё просто - мы отдаём вам 10% от стоимости заказа, если вы привели нам клиента. Для этого нужно сообщить нам зарание, что вскоре к нам обратится такой-то заказчик и он будет от вас. Дальнейшие заказы приведенного вами клиента не оплачиваются.",
        en: "It's simple - we give you 10% of the order value if you bring us a client. To do this, you need to inform us in advance that such and such a customer will soon contact us and it will be from you. Further orders from the client you referred will not be paid.",
    },
    {   id: 34,
        ru: "Также вы можете работать с нами в команде проект-менеджером! В таком случае вы будите зарабатывать не только на первом обращении клиента, а со всех платежей 20% идут вам. Проект-менеджер не только находит клиента, но и ездит на встречи, заключает договора, являясь контактным лицом компании. В таком случае мы вам покажем где и как искать клиентов, вооружим нужными материалами и знаниями.",
        en: "You can also work with us in a team as a project manager! In this case, you will earn not only on the client’s first request, but 15% of all payments goes to you. The project manager not only finds a client, but also goes to meetings, concludes contracts, being the company’s contact person. In this case, we will show you where and how to look for clients, and arm you with the necessary materials and knowledge.",
    },
// --------------------------------------------popup_4
    {   id: 35,
        ru: "Оплата наших услуг",
        en: "Payment for our services",
    },
    {   id: 36,
        ru: "Опишите ниже от кого и за что перевод, для удобства распознавания оплат.",
        en: "Describe below from whom and for what the transfer was made, for the convenience of recognizing payments.",
    },
    {   id: 37,
        ru: "Представьтесь",
        en: "Introduce yourself",
    },
    {   id: 38,
        ru: "Телефон или Email",
        en: "Phone or Email",
    },
    {   id: 39,
        ru: "Что вы оплачиваете (кратко)",
        en: "What do you pay for (briefly)",
    },
    {   id: 40,
        ru: "Сумма перевода в ₽",
        en: "Transfer amount in $",
    },
    {   id: 41,
        ru: "Перейти к оплате",
        en: "Go to payment",
    },
    // texts of "waiting" and "error" below in the text: № 50 & 51
// --------------------------------------------popup_5
    {   id: 42,
        ru: "Оставить сообщение",
        en: "Send message",
    },
    {   id: 43,
        ru: "Опишите ниже какой вопрос вас интересует, оставьте контактные данные и мы свяжемся с вами в ближайшее время. Ваше сообщение будет отправлено на почту: ",
        en: "Describe below what question interests you, leave your contact information and we will contact you as soon as possible. Your message will be sent to: ",
    },
    {   id: 44,
        ru: "Что вас интересует?",
        en: "What are you interested in?",
    },
    {   id: 45,
        ru: "Прикрепить",
        en: "Attach",
    },
    {   id: 46,
        ru: "Выберите файл",
        en: "Select file",
    },
    {   id: 47,
        ru: "Отправить",
        en: "Send",
    },
    {   id: 48,
        ru: "Ваше сообщение успешно отправлено! Пожалуйста ожидайте, мы скоро свяжемся с вами.",
        en: "Your message has been sent successfully! Please wait, we will contact you soon.",
    },
    {   id: 49,
        ru: "Произошла ошибка! Сообщение не отправлено. Пожалуйста попробуйте ещё раз или напишите нам на почту: ",
        en: "An error has occurred! Message not sent. Please try again or write to us by email: ",
    },
// --------------------------------------------popup_4 "waiting" and "error"
    {   id: 50,
        ru: "Оплата успешно сформирована и открыта в соседней вкладке. Вы можете перейти для оплаты, а эту форму закрыть.",
        en: "The payment has been successfully generated and opened in a next tab. You can close this form and go to payment.",
    },
    {   id: 51,
        ru: "Произошла ошибка! Перейти к оплате не получается. Пожалуйста попробуйте ещё раз или чуть позже.",
        en: "An error has occurred! I can't proceed to payment. Please try again or a little later.",
    },
]


































