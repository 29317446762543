import React, {useContext, useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom"
import {basicText} from "../context/BasicInterfaceText"
import {AuthContext} from '../context/Context.js'
import axios from "axios"
import garmoxa from "../img/garmoxa-arr.png"
import loading from "../img/loader.gif"

const Popups = () => { console.log('Popups')
    // context & states
    const {lang, exchange, pops, setPops, isLandscape, isLaptop, setMenuPage} = useContext(AuthContext)
    const [paySend, setPaySend] = useState(0)
    const [mesSend, setMesSend] = useState(0)
    const [escListener, setEscListener] = useState(false)

    // refs
    const popFon = useRef()
    const pop1 = useRef()
    const pop2 = useRef()
    const pop3 = useRef()
    const pop4 = useRef()
    const pop5 = useRef()
    const pop4inp1 = useRef()
    const pop4inp2 = useRef()
    const pop4inp3 = useRef()
    const pop4inp4 = useRef()
    const pop4inp5 = useRef()
    const pop4btn = useRef()
    const formPay = useRef()
    const formMess = useRef()

    // pops
    useEffect(() => {
        let dt1,dt2,dt3,dt4,dt5,dt6,dt7,dt8,dt9,dt10,dt11,dt12,dt13,dt14,dt15
        if(isLandscape){
            if(isLaptop){
                dt1 = '75vw'
                dt2 = '25vw'
                dt3 = '50vw'
                dt4 = '25vw'
                dt5 = '9vw'
                dt6 = '12vw'
                dt7 = '-1.5vw 2vw 0px -2vw'
                dt8 = '3vw'
                dt9 = '12vw'
                dt10 = '5vw'
                dt11 = '19vw'
            }else{
                dt1 = '70vw'
                dt2 = '30vw'
                dt3 = '40vw'
                dt4 = '25vw'
                dt5 = '9vw'
                dt6 = '12vw'
                dt7 = '-1.5vw 2vw 0px -2vw'
                dt8 = '3vw'
                dt9 = '12vw'
                dt10 = '5vw'
                dt11 = '19vw'
            }
            dt12 = '1200'
            dt13 = '1500'
            dt14 = '1800'
            dt15 = '2100'
        }else{
            dt1 = '100vw'
            dt2 = '0'
            dt3 = '100vw'
            dt4 = '200vw'
            dt5 = '40vw'
            dt6 = '60vw'
            dt7 = '0'
            dt8 = '15vw'
            dt9 = '100vw'
            dt10 = '25vw'
            dt11 = '100vw'
            dt12 = '650'
            dt13 = '800'
            dt14 = '1100'
            dt15 = '1400'
        }


        if(pops.p0){
            if(!isLandscape){ window.scrollTo(0, 0) }
            popFon.current.style.display = 'block'
            setTimeout(function (){
                popFon.current.style.opacity = '1'
            }, 100)
        }else{
            popFon.current.style.opacity = '0'
            pop1.current.style.opacity = '0'
            pop2.current.style.opacity = '0'
            pop3.current.style.opacity = '0'
            pop4.current.style.opacity = '0'
            pop5.current.style.opacity = '0'
            setTimeout(function (){
                popFon.current.style.display = 'none'
                pop1.current.style.display = 'none'
                pop2.current.style.display = 'none'
                pop3.current.style.display = 'none'
                pop4.current.style.display = 'none'
                pop5.current.style.display = 'none'
            }, 600)
            setTimeout(function (){
                pop1.current.style.opacity = '1'
                pop1.current.style.right = '0'
                pop1.current.style.width = '0'
                pop1.current.children[0].style.display = 'none'
                pop1.current.children[0].style.opacity = '0'
                pop1.current.children[1].style.display = 'none'
                pop1.current.children[1].children[0].style.display = 'none'
                pop1.current.children[1].children[0].style.maxHeight = '0'
                pop1.current.children[1].children[1].style.display = 'none'
                pop1.current.children[1].children[1].style.maxHeight = '0'
                pop1.current.children[1].children[2].style.display = 'none'
                pop1.current.children[1].children[2].style.maxHeight = '0'

                pop2.current.style.opacity = '1'
                pop2.current.style.right = '0'
                pop2.current.style.width = '0'
                pop2.current.children[0].style.display = 'none'
                pop2.current.children[0].style.opacity = '0'
                pop2.current.children[0].style.margin = '0'
                pop2.current.children[0].children[2].style.maxHeight = '0'
                pop2.current.children[1].style.display = 'none'
                pop2.current.children[1].children[0].style.maxHeight = '0'

                pop3.current.style.opacity = '1'
                pop3.current.style.right = '0'
                pop3.current.style.width = '0'
                pop3.current.children[0].style.display = 'none'
                pop3.current.children[0].style.opacity = '0'
                pop3.current.children[1].style.display = 'none'
                pop3.current.children[1].children[0].style.display = 'none'
                pop3.current.children[1].children[0].style.maxHeight = '0'
                pop3.current.children[1].children[1].style.display = 'none'
                pop3.current.children[1].children[1].style.maxHeight = '0'
                pop3.current.children[1].children[2].style.display = 'none'
                pop3.current.children[1].children[2].style.maxHeight = '0'

                pop4.current.style.opacity = '1'
                pop4.current.style.right = '0'
                pop4.current.style.width = '0'
                pop4.current.children[0].style.display = 'none'
                pop4.current.children[0].style.opacity = '0'
                pop4.current.children[1].style.display = 'none'
                pop4.current.children[1].children[0].style.maxHeight = '0'
                pop4.current.children[1].children[1].style.maxHeight = '0'
                pop4.current.children[1].children[1].style.opacity = '0'
                setPaySend(0)

                pop5.current.style.opacity = '1'
                pop5.current.style.right = '0'
                pop5.current.style.width = '0'
                pop5.current.children[0].style.display = 'none'
                pop5.current.children[0].style.opacity = '0'
                pop5.current.children[1].style.display = 'none'
                pop5.current.children[1].children[0].style.maxHeight = '0'
                pop5.current.children[1].children[1].style.maxHeight = '0'
                pop5.current.children[1].children[1].style.opacity = '0'
                pop5.current.style.backgroundImage = 'unset'
                setMesSend(0)
            }, 700)
        }
        //-----------------------------------------------
        if(pops.p1){
            pop1.current.style.display = 'block'
            setTimeout(function (){
                pop1.current.style.width = dt1
            }, 100)
            setTimeout(function (){
                pop1.current.style.right = dt2
                pop1.current.style.width = dt3
                pop1.current.children[0].style.display = 'grid'
            }, 600)
            setTimeout(function (){
                pop1.current.children[0].style.opacity = '1'
                pop1.current.children[1].style.display = 'block'
                pop1.current.children[1].children[0].style.display = 'block'
            }, dt12)
            setTimeout(function (){
                pop1.current.children[1].children[0].style.maxHeight = dt4
                pop1.current.children[1].children[1].style.display = 'block'
            }, dt13)
            setTimeout(function (){
                pop1.current.children[1].children[1].style.maxHeight = dt4
                pop1.current.children[1].children[2].style.display = 'block'
            }, dt14)
            setTimeout(function (){
                pop1.current.children[1].children[2].style.maxHeight = dt4
            }, dt15)
        }
        //-----------------------------------------------
        if(pops.p2){
            pop2.current.style.display = 'block'
            setTimeout(function (){
                pop2.current.style.width = dt1
            }, 100)
            setTimeout(function (){
                pop2.current.style.right = dt2
                pop2.current.style.width = dt3
                pop2.current.children[0].style.display = 'grid'
            }, 600)
            setTimeout(function (){
                pop2.current.children[0].style.opacity = '1'
                pop2.current.children[1].style.display = 'block'
            }, dt12)
            setTimeout(function (){
                pop2.current.children[0].children[2].style.maxHeight = dt5
            }, dt13)
            setTimeout(function (){
                pop2.current.children[1].children[0].style.maxHeight = dt6
                pop2.current.children[0].style.margin = dt7
            }, 1800)
        }
        //-----------------------------------------------
        if(pops.p3){
            pop3.current.style.display = 'block'
            setTimeout(function (){
                pop3.current.style.width = dt1
            }, 100)
            setTimeout(function (){
                pop3.current.style.right = dt2
                pop3.current.style.width = dt3
                pop3.current.children[0].style.display = 'grid'
            }, 600)
            setTimeout(function (){
                pop3.current.children[0].style.opacity = '1'
                pop3.current.children[1].style.display = 'block'
                pop3.current.children[1].children[0].style.display = 'block'
            }, dt12)
            setTimeout(function (){
                pop3.current.children[1].children[0].style.maxHeight = dt4
                pop3.current.children[1].children[1].style.display = 'block'
            }, dt13)
            setTimeout(function (){
                pop3.current.children[1].children[1].style.maxHeight = dt4
                pop3.current.children[1].children[2].style.display = 'block'
            }, dt14)
            setTimeout(function (){
                pop3.current.children[1].children[2].style.maxHeight = dt4
            }, dt15)
        }
        //-----------------------------------------------
        if(pops.p4){
            pop4.current.style.display = 'block'
            setTimeout(function (){
                pop4.current.style.width = dt1
            }, 100)
            setTimeout(function (){
                pop4.current.style.right = dt2
                pop4.current.style.width = dt3
                pop4.current.children[0].style.display = 'grid'
            }, 600)
            setTimeout(function(){
                pop4.current.children[0].style.opacity = '1'
                pop4.current.children[1].style.display = 'block'
            }, dt12)
            setTimeout(function (){
                pop4.current.children[1].children[0].style.maxHeight = dt8
            }, dt13)
            setTimeout(function (){
                pop4.current.children[1].children[1].style.opacity = '1'
                pop4.current.children[1].children[1].style.maxHeight = dt9
            }, 1900)
        }
        //-----------------------------------------------
        if(pops.p5){
            pop5.current.style.display = 'block'
            setTimeout(function (){
                pop5.current.style.width = dt1
            }, 100)
            setTimeout(function (){
                pop5.current.style.right = dt2
                pop5.current.style.width = dt3
                pop5.current.children[0].style.display = 'grid'
            }, 600)
            setTimeout(function(){
                pop5.current.children[0].style.opacity = '1'
                pop5.current.children[1].style.display = 'block'
            }, dt12)
            setTimeout(function (){
                pop5.current.children[1].children[0].style.maxHeight = dt10
            }, dt13)
            setTimeout(function (){
                pop5.current.children[1].children[1].style.opacity = '1'
                pop5.current.children[1].children[1].style.maxHeight = dt11
            }, 1900)
        }
    }, [pops, isLandscape, isLaptop])

    // functions
    if(!escListener){
        setEscListener(true)
        window.addEventListener("keydown", function(ev){
            if(ev.keyCode === 27){ // код клавиши ЭСКЕЙП
                setPops({
                    p0: false,
                    p1: false,
                    p2: false,
                    p3: false,
                    p4: false,
                    p5: false,
                    p6: false,
                    p7: false,
                    p8: false,
                    p9: false,
                })
                garmoxaRollUp()
            }
        });
    }
    function garmoxaRollUp() {
        pop1.current.children[1].children[0].classList.remove('active')
        pop1.current.children[1].children[1].classList.remove('active')
        pop1.current.children[1].children[2].classList.remove('active')
        pop3.current.children[1].children[0].classList.remove('active')
        pop3.current.children[1].children[1].classList.remove('active')
        pop3.current.children[1].children[2].classList.remove('active')
    }
    function garmoxaRoll(e){
        let obj
        if(e.target.classList.contains('popup-garmoxa-tit')){
            obj = e.target.parentNode
        }else{
            if(e.target.classList.contains('popup-garmoxa-img')){
                obj = e.target.parentNode.parentNode
            }else{
                return
            }
        }

        if(obj.classList.contains('active')){
            obj.classList.remove('active')
        }else{
            garmoxaRollUp()
            obj.classList.add('active')
        }
    }
    function getName(str) {
        let i
        if (str.lastIndexOf('\\')){
            i = str.lastIndexOf('\\')+1
        }
        else{
            i = str.lastIndexOf('/')+1
        }
        document.getElementsByClassName("popup-file-lbl")[0].innerHTML = str.slice(i)
    }
    function payFinish(heightSz, varSumm) {
        pop4inp5.current.value = varSumm
        pop4btn.current.click()

        setPaySend(1)
        pop4.current.style.backgroundImage = 'unset'
        pop4.current.children[1].children[0].style.maxHeight = heightSz
    }
    function sendPay() {
        let err = 0
        let contactInput = formPay.current.children[1]
        let summInput = formPay.current.children[3]

        // collect data for send
        let varName = formPay.current.children[0].value
        let varContact = contactInput.value
        let varText = formPay.current.children[2].value
        let varSumm = summInput.value

        // check input on errors
        if (varContact.length < 7){
            err = 1
            contactInput.style.backgroundColor = '#ff000033'
            setTimeout(function () {
                contactInput.style.backgroundColor = 'transparent'
            }, 2000)
        }
        if (varSumm.length < 2){
            err = 1
            summInput.style.backgroundColor = '#ff000033'
            setTimeout(function () {
                summInput.style.backgroundColor = 'transparent'
            }, 2000)
        }else{
            varSumm = varSumm * 1
        }
        if(err === 1){return}

        // animation
        pop4.current.style.backgroundImage = 'url("' + loading + '")'
        formPay.current.style.maxHeight = "0"
        formPay.current.style.opacity = "0"
        pop4.current.children[1].children[0].style.maxHeight = "0"

        // transfer data to hidden payment from
        pop4inp1.current.value = varContact
        pop4inp2.current.value = varContact
        pop4inp3.current.value = varName
        pop4inp4.current.value = varText

        // convert USD to RUB if we on English version of site
        let heightSz = "3vw"
        if(!isLandscape){heightSz = "15vw"}
        if(lang === 'en'){
            varSumm = varSumm * exchange
            payFinish(heightSz, varSumm)
        }else{
            payFinish(heightSz, varSumm)
        }
    }
    function sendMess() {
        // animation
        pop5.current.style.backgroundImage = 'url("' + loading + '")'
        formMess.current.style.maxHeight = "0"
        formMess.current.style.opacity = "0"
        pop5.current.children[1].children[0].style.maxHeight = "0"

        // collect data for send
        let varName = formMess.current.children[0].value
        let varContact = formMess.current.children[1].value
        let varText = formMess.current.children[2].value
        let formData = new FormData()
        formData.append("addedFile", formMess.current.children[3].children[2].files[0])

        // sending and check
        axios.post(`/tomail.php?name=${varName}&contact=${varContact}&text=${varText}`, formData
        ).then((res) => {
                let heightSz = "5vw"
                if(!isLandscape){heightSz = "25vw"}

                if(res.data === 911 || res.data === '911'){
                    setMesSend(1)
                    pop5.current.style.backgroundImage = 'unset'
                    pop5.current.children[1].children[0].style.maxHeight = heightSz
                }else{
                    setMesSend(2)
                    pop5.current.style.backgroundImage = 'unset'
                    pop5.current.children[1].children[0].style.maxHeight = heightSz
                    console.log(res.data)
                }
            }
        )
    }

    return (
        <div className="popup-zone">
            <div className="popup-fon" ref={popFon}> </div>
            <div className={`popup popup-cost ${pops.p1 ? "popup-active" : ""}`} ref={pop1}>
                <div className="popup-top">
                    <h2>{basicText[11][lang]}</h2>
                    <div className="popup-close" onClick={() => {setPops({
                        p0: false,
                        p1: false,
                        p2: false,
                        p3: false,
                        p4: false,
                        p5: false,
                        p6: false,
                        p7: false,
                        p8: false,
                        p9: false,
                    }); garmoxaRollUp()}}>×</div>
                </div>
                <div className="popup-body">
                    <div className="popup-garmoxa" onClick={(e) => garmoxaRoll(e)}>
                        <div className="popup-garmoxa-tit">
                            <img src={garmoxa} alt="" className="popup-garmoxa-img"/>
                            {basicText[12][lang]}
                        </div>
                        <p className="popup-garmoxa-text">
                            {basicText[13][lang] + ' '}
                            <Link to={`/calculator${lang !== 'en' ? '/'+lang : ''}`} onClick={() => {
                                setPops({
                                p0: false,
                                p1: false,
                                p2: false,
                                p3: false,
                                p4: false,
                                p5: false,
                                p6: false,
                                p7: false,
                                p8: false,
                                p9: false,
                            })
                                garmoxaRollUp()
                                setMenuPage('calculator')
                            }}>{basicText[14][lang]}</Link>.
                        </p>
                    </div>
                    <div className="popup-garmoxa" onClick={(e) => garmoxaRoll(e)}>
                        <div className="popup-garmoxa-tit">
                            <img src={garmoxa} alt="" className="popup-garmoxa-img"/>
                            {basicText[15][lang]}
                        </div>
                        <p className="popup-garmoxa-text">
                            {basicText[16][lang] + ' '}
                            <a href="https://docs.google.com/spreadsheets/d/1ic76dVtwfIlPw7222Dksinz4pMvLbwyYXAhLfQSpK4k" target="_blank" rel="noreferrer">{basicText[17][lang]}</a>
                            {' ' + basicText[18][lang] + ' '}
                            <a href={`mailto:${basicText[9]['data']}`}>{basicText[9]['data']}</a>
                            {' ' + basicText[19][lang]}
                        </p>
                    </div>
                    <div className="popup-garmoxa" onClick={(e) => garmoxaRoll(e)}>
                        <div className="popup-garmoxa-tit">
                            <img src={garmoxa} alt="" className="popup-garmoxa-img"/>
                            {basicText[20][lang]}
                        </div>
                        <p className="popup-garmoxa-text">
                            {basicText[21][lang] + " "}
                            <a href={`https://wa.me/+${basicText[6]['data']}`} target="_blank" rel="noreferrer">{basicText[22][lang]}</a>
                            {" " + basicText[23][lang] + " "}
                            <a href={`tel:+${basicText[8]['data']}`}>{basicText[8]['data']}</a>.
                        </p>
                    </div>
                </div>
            </div>
            <div className={`popup popup-bro ${pops.p2 ? "popup-active" : ""}`} ref={pop2}>
                <div className="popup-top">
                    <h2>{basicText[32][lang]}</h2>
                    <div className="popup-close" onClick={() => setPops({
                        p0: false,
                        p1: false,
                        p2: false,
                        p3: false,
                        p4: false,
                        p5: false,
                        p6: false,
                        p7: false,
                        p8: false,
                        p9: false,
                    })}>×</div>
                    <p className="popup-bro-subtit">{basicText[33][lang]}</p>
                </div>
                <div className="popup-body">
                    <p className="popup-text">{basicText[34][lang]}</p>
                </div>
            </div>
            <div className={`popup popup-sale ${pops.p3 ? "popup-active" : ""}`} ref={pop3}>
                <div className="popup-top">
                    <h2>{basicText[24][lang]}</h2>
                    <div className="popup-close" onClick={() => {setPops({
                        p0: false,
                        p1: false,
                        p2: false,
                        p3: false,
                        p4: false,
                        p5: false,
                        p6: false,
                        p7: false,
                        p8: false,
                        p9: false,
                    }); garmoxaRollUp()}}>×</div>
                </div>
                <div className="popup-body">
                    <div className="popup-garmoxa" onClick={(e) => garmoxaRoll(e)}>
                        <div className="popup-garmoxa-tit">
                            <img src={garmoxa} alt="" className="popup-garmoxa-img"/>
                            {basicText[25][lang]}
                        </div>
                        <p className="popup-garmoxa-text">
                            {basicText[26][lang]}
                            <Link to={`/files${lang !== 'en' ? '/'+lang : ''}`} onClick={() => {
                                setPops({
                                p0: false,
                                p1: false,
                                p2: false,
                                p3: false,
                                p4: false,
                                p5: false,
                                p6: false,
                                p7: false,
                                p8: false,
                                p9: false,
                            })
                                garmoxaRollUp()
                                setMenuPage('files')
                            }}>{basicText[27][lang]}</Link>.
                        </p>
                    </div>
                    <div className="popup-garmoxa" onClick={(e) => garmoxaRoll(e)}>
                        <div className="popup-garmoxa-tit">
                            <img src={garmoxa} alt="" className="popup-garmoxa-img"/>
                            {basicText[28][lang]}
                        </div>
                        <p className="popup-garmoxa-text">
                            {basicText[29][lang]}
                        </p>
                    </div>
                    <div className="popup-garmoxa popup-garmoxa-partners" onClick={(e) => garmoxaRoll(e)}>
                        <div className="popup-garmoxa-tit">
                            <img src={garmoxa} alt="" className="popup-garmoxa-img"/>
                            {basicText[30][lang]}
                        </div>
                        <p className="popup-garmoxa-text">
                            {basicText[31][lang]}
                        </p>
                    </div>
                </div>
            </div>
            <div className={`popup popup-pay ${pops.p4 ? "popup-active" : ""}`} ref={pop4}>
                <div className="popup-top">
                    <h2>{basicText[35][lang]}</h2>
                    <div className="popup-close" onClick={() => setPops({
                        p0: false,
                        p1: false,
                        p2: false,
                        p3: false,
                        p4: false,
                        p5: false,
                        p6: false,
                        p7: false,
                        p8: false,
                        p9: false,
                    })}>×</div>
                </div>
                <div className="popup-body">
                    <p className="popup-text" style={{
                        color: paySend === 1 ? 'green' : '',
                        fontWeight: paySend === 1 ? '700' : '300',
                    }}>{
                        paySend === 0
                        ?
                            basicText[36][lang]
                        :
                            basicText[50][lang]
                    }</p>
                    <div className="popup-form" ref={formPay}>
                        <input type="text" placeholder={basicText[37][lang]} autoComplete="off"/>
                        <input type="text" placeholder={basicText[38][lang]} autoComplete="off"/>
                        <textarea placeholder={basicText[39][lang]} autoComplete="off"/>
                        <input type="text" placeholder={basicText[40][lang]} autoComplete="off" onInput={(e) => {
                            let tempStr = e.target.value
                            tempStr = tempStr.replace(/[^0-9]/g,"")
                            e.target.value = tempStr
                        }}/>
                        <div className="popup-btn" onClick={() => sendPay()}>{basicText[41][lang]}</div>
                    </div>
                </div>
                <div style={{display:'none'}}>
                    <form target="_blank" className="yoomoney-payment-form" action="https://yookassa.ru/integration/simplepay/payment" method="post" acceptCharset="utf-8">
                        <div className="ym-customer-info">
                            <div className="ym-block-title">О покупателе</div>
                            <input name="cps_email" className="ym-input" placeholder="Email" type="text" autoComplete="off" ref={pop4inp1}/>
                            <input name="cps_phone" className="ym-input" placeholder="Телефон" type="text" autoComplete="off" ref={pop4inp2}/>
                            <input name="custName" className="ym-input" placeholder="ФИО" type="text" autoComplete="off" ref={pop4inp3}/>
                            <textarea className="ym-textarea" name="orderDetails" placeholder="Комментарий" autoComplete="off" ref={pop4inp4}/>
                        </div>
                        <div className="ym-hidden-inputs">
                            <input name="shopSuccessURL" type="hidden" defaultValue="https://samborsky.pro/payment-good"/>
                            <input name="paymentType" type="hidden" defaultValue="AC"/>
                        </div>
                        <input name="customerNumber" type="hidden" defaultValue="Оплата услуг Samborsky.Pro"/>
                        <div className="ym-payment-btn-block ym-before-line ym-align-space-between">
                            <div className="ym-input-icon-rub">
                                <input name="sum" placeholder="0.00" className="ym-input ym-sum-input ym-required-input" type="number" step="any" defaultValue="100" autoComplete="off"  ref={pop4inp5}/>
                            </div>
                            <button ref={pop4btn} data-text="Оплатить" className="ym-btn-pay ym-result-price"><span className="ym-text-crop">Оплатить</span> <span className="ym-price-output"> </span></button>
                            <svg className="ym-logo" width="114" height="27" viewBox="0 0 114 27" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="logoTitle" role="img"> </svg>
                        </div>
                        <input name="shopId" type="hidden" defaultValue="292012"/>
                    </form>
                </div>
            </div>
            <div className={`popup popup-mess ${pops.p5 ? "popup-active" : ""}`} ref={pop5}>
                <div className="popup-top">
                    <h2>{basicText[42][lang]}</h2>
                    <div className="popup-close" onClick={() => setPops({
                        p0: false,
                        p1: false,
                        p2: false,
                        p3: false,
                        p4: false,
                        p5: false,
                        p6: false,
                        p7: false,
                        p8: false,
                        p9: false,
                    })}>×</div>
                </div>
                <div className="popup-body">
                    <p className="popup-text" style={{
                        color: mesSend === 2
                            ? 'red'
                            : mesSend === 1 ? 'green' : '',
                        fontWeight: mesSend === 2 || mesSend === 1 ? '700' : '300',
                    }}>{
                        mesSend === 0
                        ?
                            basicText[43][lang] + basicText[9]['data']
                        :
                            mesSend === 1
                            ?
                                basicText[48][lang]
                            :
                                basicText[49][lang] + basicText[9]['data']
                    }</p>
                    <div className="popup-form" ref={formMess}>
                        <input type="text" placeholder={basicText[37][lang]} autoComplete="off"/>
                        <input type="text" placeholder={basicText[38][lang]} autoComplete="off"/>
                        <textarea placeholder={basicText[44][lang]} autoComplete="off"/>
                        <div className="popup-file">
                            <div className="popup-file-btn">{basicText[45][lang]}</div>
                            <div className="popup-file-lbl">{basicText[46][lang]}</div>
                            <input id="popup-file-input" type="file" onChange={(e) => getName(e.target.value)} autoComplete="off"/>
                            <label id="popup-file-label" htmlFor="popup-file-input"> </label>
                        </div>
                        <div className="popup-btn" onClick={() => sendMess()}>{basicText[47][lang]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popups;